import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
export default function RoleLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const user = useSelector((state) => state.Main.user);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // 권한 체크 로직
    const projectRole = user?.includedProjectList?.filter(
      (item) => item.projectId == projectId,
    ) || []

    // 권한이 없으면 다른 페이지로 리다이렉트
    if (projectRole.length == 0) {
      toast.error('권한이 없는 프로젝트입니다.');
      navigate('/projects');
    } else {
      setLoaded(true);
    }
    return () => { };
  }, []);

  if (loaded) {
    return (
      <>
        <Outlet />
      </>
    );
  } else {
    return null;
  }
}
