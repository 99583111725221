import React, { useEffect, memo } from 'react';
import { useReducer, useState } from 'react';
import Pagination from './Pagination';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

const editableCell = ({ getValue, row: { index }, column: { id }, table }) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const onBlur = () => {
    if (table.options.meta && table.options.meta.updateData) {
      table.options.meta.updateData(index, id, value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <label>
      <input
        type="text"
        className="input_hauto py-1.5 px-2 f_15 w-full bg-slate-200/50"
        placeholder="단어입력"
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    </label>
  );
};

export default function ReactTable_CS({
  columns,
  data,
  setData,
  totalCount,
  className = '',
  useFooter = false,
  onSortingChange,
  onRowClick = (row = {}) => { },
  rowStyle = {},
  pageInfo,
}) {
  const rerender = useReducer(() => ({}), {})[1];
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageInfo.pageSize || 10,
  });
  const totalPages = Math.ceil(totalCount / pagination.pageSize);

  // 페이지 버튼 배열 생성
  const pages = [];

  // 최대 표시할 페이지 버튼 개수
  const maxPageButtons = 5;

  // 시작 페이지 계산 (현재 페이지에 따라 동적으로 범위 설정)
  const startPage =
    Math.floor(pagination.pageIndex / maxPageButtons) * maxPageButtons + 1;

  // 끝 페이지 계산 (최대 totalPages를 넘지 않도록)
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // 클라이언트에서 역순 인덱스 계산
  const calculateReverseIndex = (itemIndex) => {
    return totalCount - itemIndex;
  };
  const handleChangePage = (page) => {
    setPagination({ ...pagination, pageIndex: page - 1 });
  };
  const columnHelper = createColumnHelper();
  const getColumns = () => {
    let result = [];
    columns?.forEach((v, i) => {
      result.push(
        columnHelper.accessor(v.id, {
          //리턴값에 html 작성가능.
          //v.editable true일때 cell : editableCell 추가필요.
          cell: v.editable
            ? editableCell
            : (info) => {
              const { getValue, row, column, table, renderValue } = info;
              if (v.cellRender && typeof v.cellRender == 'function') {
                return <>{v.cellRender(row.original, table.options.meta)}</>;
              } else {
                return v.id == 'index'
                  ? row.index + 1
                  : v.id == 'reverse'
                    ? calculateReverseIndex(row.index)
                    : renderValue();
              }
            },
          header: () => {
            if (v.sortOption) {
              let sort = v.sortOption.sort;
              if (sort == '') {
                sort = 'asc';
              } else if (sort == 'asc') {
                sort = 'desc';
              } else if (sort == 'desc') {
                sort = '';
              }
              return (
                <>
                  <span
                    onClick={() => {
                      onSortingChange(v.id, sort);
                    }}
                  >
                    {v.header}
                  </span>
                </>
              );
            } else {

              if (typeof v.header == 'function') {
                return (
                  <>
                    {v.header()}
                  </>
                )
              } else {
                return v.header;
              }
            }
          },
          size: v.size,
          id: v.id,
          sortOption: v.sortOption,
          textAlign: v.textAlign || '',
        }),
      );
    });
    return result;
  };
  const table = useReactTable({
    data: data || [],
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        if (!_.isEqual(data[rowIndex][columnId], value)) {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                  edited: true,
                };
              }
              return row;
            }),
          );
        }
      },
      deleteData: (row) => {
        setData((old) => {
          let result = [];
          let rowIndex = _.findIndex(old, row);
          old.map((row, index) => {
            if (index != rowIndex) {
              result.push(row);
            }
          });
          console.log('🚀 ~ setData ~ result:', rowIndex, result);
          return result;
        });
      },
    },
    autoResetAll: false,
    rowCount: totalCount,
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  return (
    <>
      <table className={className}>
        <thead className="thead1">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-center" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <>
                  <th
                    key={header.id}
                    style={
                      header.column.columnDef.size != undefined
                        ? {
                          width: header.column.columnDef.size,
                        }
                        : {}
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    {header.column.columnDef.sortOption && (
                      <>
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.columnDef.sortOption.sort] ?? null}
                      </>
                    )}
                  </th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="tbody1 f_15 text-center">
          {table.getRowModel().rows.map((row, index) => (
            <>
              <tr
                key={row.id}
                style={{ ...(row.original?.style || {}) }}
                onClick={() => {
                  onRowClick?.({ ...row.original }, index);
                }}
              >
                {row.getVisibleCells().map((cell) => (

                  <>
                    <td
                      className={`py-1.5 px-3 ${cell.column.columnDef.textAlign}`}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  </>
                ))}
              </tr>
            </>
          ))}
        </tbody>
        {useFooter && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>

      {totalCount > 0 && (
        <ul class="mt-10 page gap-1">
          {/* 이전 페이지 버튼 */}
          <li
            class={pagination.pageIndex > 0 ? '' : 'deactivation'}
            onClick={() => {
              {
                pagination.pageIndex + 1 > 1 &&
                  handleChangePage(pagination.pageIndex);
              }
            }}
          >
            <img src="/images/page_prev.png" width="19" class="opacity-30" />
          </li>

          {pages.map((page) => (
            <li
              key={page}
              className={pagination.pageIndex + 1 == page ? 'active' : ''}
              onClick={() => handleChangePage(page)}
            >
              {page}
            </li>
          ))}
          <li
            className={pagination.pageIndex < totalPages ? '' : 'deactivation'}
            onClick={() => {
              {
                pagination.pageIndex + 1 < totalPages &&
                  handleChangePage(pagination.pageIndex + 2);
              }
            }}
          >
            <img src="/images/page_next.png" width="19" class="opacity-30" />
          </li>
        </ul>
      )}
    </>
  );
}
