import React, { useEffect, memo } from 'react';
import { useReducer, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

const editableCell = ({ getValue, row: { index }, column: { id }, table }) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const onBlur = () => {
    if (table.options.meta && table.options.meta.updateData) {
      table.options.meta.updateData(index, id, value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value || ''}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

export default function ReactTable({
  columns,
  data,
  setData,
  totalCount,
  pageInfo = { currentPage: 0, pageSize: 10 },
  className = '',
  useFooter = false,
  onSortingChange,
  onRowClick = (row = {}) => { },
  rowStyle = {},
}) {
  const calculateIndex = (itemIndex) => {
    return (pageInfo.currentPage - 1) * pageInfo.pageSize + itemIndex + 1;
  };
  // 클라이언트에서 역순 인덱스 계산
  const calculateReverseIndex = (itemIndex) => {
    return (
      totalCount - ((pageInfo.currentPage - 1) * pageInfo.pageSize + itemIndex)
    );
  };
  const rerender = useReducer(() => ({}), {})[1];

  const columnHelper = createColumnHelper();
  const getColumns = () => {
    let result = [];
    columns?.forEach((v, i) => {
      result.push(
        columnHelper.accessor(v.id, {
          //리턴값에 html 작성가능.
          //v.editable true일때 cell : editableCell 추가필요.
          cell: v.editable
            ? editableCell
            : (info) => {
              const { getValue, row, column, table, renderValue } = info;
              if (v.cellRender && typeof v.cellRender == 'function') {
                return <>{v.cellRender(row.original)}</>;
              } else {
                return v.id == 'index'
                  ? calculateIndex(row.index)
                  : v.id == 'reverse'
                    ? calculateReverseIndex(row.index)
                    : renderValue();
              }
            },
          header: () => {
            if (v.sortOption) {
              let sort = v.sortOption.sort;
              if (sort == '') {
                sort = 'asc';
              } else if (sort == 'asc') {
                sort = 'desc';
              } else if (sort == 'desc') {
                sort = '';
              }
              return (
                <>
                  <span
                    style={{cursor:"pointer"}}
                    onClick={() => {
                      onSortingChange(v.id, sort);
                    }}
                  >
                    {v.header}
                  </span>
                </>
              );
            } else {
              if (typeof v.header == 'function') {
                return (
                  <>
                    {v.header()}
                  </>
                )
              } else {
                return v.header;
              }
            }
          },
          size: v.size,
          id: v.id,
          sortOption: v.sortOption,
          textAlign: v.textAlign || '',
        }),
      );
    });
    return result;
  };
  const table = useReactTable({
    data: data || [],
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        if (!_.isEqual(data[rowIndex][columnId], value)) {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
        }
      },
    },
    autoResetAll: false,
    manualPagination: true,
    rowCount: totalCount,
  });

  return (
    <>
      <table className={className}>
        <thead className="thead1">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <>
                  <th
                    className={`px-3 ${header.column.columnDef.textAlign || 'text-center'}`}
                    key={header.id}
                    style={
                      header.column.columnDef.size != undefined
                        ? {
                          width: header.column.columnDef.size,
                        }
                        : {}
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    {header.column.columnDef.sortOption && (
                      <>
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.columnDef.sortOption.sort] ?? null}
                      </>
                    )}
                  </th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="tbody1 f_15 text-center">
          {table.getRowModel().rows.map((row, index) => (
            <>
              <tr
                key={row.id}
                style={{ ...(row.original?.style || {}) }}
                onClick={() => {
                  onRowClick?.({ ...row.original }, index);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <>
                    <td
                      style={{ wordBreak: 'break-word' }}
                      className={`py-2.5 px-5 ${cell.column.columnDef.textAlign}`}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  </>
                ))}
              </tr>
            </>
          ))}
        </tbody>
        {useFooter && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </>
  );
}
