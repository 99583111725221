import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUser } from '../../store/modules/Main';
import axiosClient from '../../lib/axiosClient';
import { toast } from 'react-toastify';

export default function MyPage({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Main.user);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    id: null,
    name: null,
    email: null,
  });

  const logout = async (logoutCallback = () => {}) => {
    let result = true;
    axiosClient({
      method: 'POST',
      url: '/api/v1/logout',
    })
      .then(async (res) => {
        if (res.data.result) {
          logoutCallback?.();
          navigate('/login');
          setTimeout(() => {
            dispatch(setUser({ user: null }));
          }, 0);
        }
      })
      .catch((error) => {
        result = false;
        toast.error('다시 시도해주세요');
      });
    return result;
  };

  const update = () => {
    axiosClient({
      method: 'POST',
      url: '/api/main/update',
      data: {
        userId: userData.id,
        name: userData.name,
        email: userData.email,
      },
    })
      .then((res) => {
        toast.success('회원 정보 수정 완료');
      })
      .catch((error) => {
        toast.error('다시 시도해주세요');
      });
  };

  const userDelete = () => {
    axiosClient({
      method: 'POST',
      url: '/api/main/delete',
      data: {
        userId: userData.id,
      },
    })
      .then((res) => {
        toast.info('회원 탈퇴가 완료됐습니다.');
        logout();
      })
      .catch((error) => {
        toast.error('다시 시도해주세요');
      });
  };

  useEffect(() => {
    if (_.isEmpty(user)) {
      toast.error('로그인후 이용하실수있습니다.');
      navigate('/');
    }
  }, []);

  const userDataChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    setUserData((userData) => ({
      ...userData,
      [id]: value,
    }));
  };

  return (
    <form>
      <ul class="">
        <li>
          <label for="id">
            id:
            <input
              type="text"
              defaultValue={user?.userId}
              id="id"
              readOnly
              style={{ backgroundColor: 'transparent' }}
              onChange={userDataChange}
            ></input>
          </label>
        </li>

        <li>
          <label for="name">
            name:{' '}
            <input
              type="text"
              defaultValue={user?.name}
              id="name"
              onChange={userDataChange}
            ></input>
          </label>
        </li>

        <li>
          <label for="email">
            email:{' '}
            <input
              type="text"
              defaultValue={user?.email}
              id="email"
              onChange={userDataChange}
            ></input>
          </label>
        </li>
      </ul>
      <button
        type="button"
        className="error"
        style={{ width: '100%', height: '80%' }}
        onClick={() => {
          logout(() => {
            toast.success(
              '정상적으로 로그아웃되었습니다. \n로그인 페이지로 이동합니다.',
            );
          });
        }}
      >
        로그아웃
      </button>
      <button
        type="button"
        className="error"
        style={{
          width: '100%',
          height: '80%',
          marginTop: '20px',
          borderColor: 'violet',
        }}
        onClick={update}
      >
        정보수정
      </button>

      <button
        type="button"
        className="error"
        style={{
          width: '100%',
          height: '80%',
          marginTop: '20px',
        }}
        onClick={userDelete}
      >
        회원탈퇴
      </button>
    </form>
  );
}
