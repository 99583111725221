import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

function Find() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Main.user);
  const [userData, setUserData] = useState({
    name: null,
    email: null,
  });
  const [userId, setUserId] = useState();

  const userDataChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    setUserData((userData) => ({
      ...userData,
      [id]: value,
    }));
  };

  const findId = () => {
    axios({
      method: 'post',
      url: '/api/main/findId',
      data: {
        name: userData.name,
        email: userData.email,
      },
    })
      .then((response) => {
        const data = response.data;
        setUserId(data.userId);
      })
      .catch((error) => {
        toast.error('일치하는 아이디가 없습니다. 다시 입력해주세요');
      });
  };

  return (
    <form>
      <ul class="">
        <li>
          <label for="name">
            이름:{' '}
            <input
              type="test"
              value={userData.name}
              id="name"
              onChange={userDataChange}
            />
          </label>
          <label for="email">
            email:{' '}
            <input
              type="email"
              value={userData.email}
              id="email"
              onChange={userDataChange}
            />
          </label>
          <button
            type="button"
            className="error"
            style={{ width: '100px', height: '50px', background: 'grey' }}
            onClick={findId}
          >
            아이디찾기
          </button>
        </li>
        <div class={userId ? '' : 'hidden'}>사용자 id: {userId}</div>
      </ul>
    </form>
  );
}

export default Find;
