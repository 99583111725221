import { React, useEffect, useState } from 'react';
import Modal from 'react-modal';
import axiosClient from '../../../../lib/axiosClient';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function BasicAnalysisOptionPop({ isOpen, setIsOpen }) {
  /* 옵션 메뉴바 (빈도수 분석: 0, 유사도 분석:1 , 네트워크 분석:2) */
  const [tab, setTab] = useState(0);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = parseInt(searchParams.get('history'));
  const [analysisOption, setAnalysisOption] = useState({});

  const getAnalysisOptions = () => {
    axiosClient({
      method: 'post',
      url: '/api/analysis/getAnalysisOption',
      data: {
        historyId,
        projectId,
      },
    }).then(async (result) => {
      console.log('🚀 ~ .then ~ result:', result);
      setAnalysisOption(result.data.data);
    });
  };

  useEffect(() => {
    getAnalysisOptions();
  }, []);

  const saveOptions = () => {
    axiosClient({
      method: 'post',
      url: '/api/analysis/updateAnalysisOptions',
      data: {
        ...analysisOption,
      },
    }).then(async (result) => {
      if (result.status == 200) {
        setIsOpen(false);
      }
    });
  };

  if (analysisOption != {}) {
    return (
      <>
        <article className="popbox popbox1" style={{ display: 'flex' }}>
          <ul className="pop_container pt-8" style={{ width: '550px' }}>
            <li className="shrink-0 px-8">
              <p className="poptit flex items-center">
                <span className="img mr-2 mt-0.5">
                  <img src="/images/icon_setting1.png" width="22" />
                </span>
                <span className="tit">기본분석 세부옵션 설정 </span>
              </p>
              <p className="mt-1 text-black/60">
                기본분석 시 사용할 세부 옵셜을 설정하세요.
              </p>
              <a
                href="javascript:void(0)"
                className="close1 btn_pop1"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
              </a>
            </li>
            <li className="p-8 grow pop_contents">
              <ul id="tabs" className="gap-1">
                <li id={`${tab == 0 ? 'current' : ''}`}>
                  <a
                    href="javascript:void(0)"
                    title="tab1"
                    className="px-4"
                    onClick={() => {
                      setTab(0);
                    }}
                  >
                    빈도수 분석
                  </a>
                </li>
                <li id={`${tab == 1 ? 'current' : ''}`}>
                  <a
                    href="javascript:void(0)"
                    title="tab2"
                    className="px-4"
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    유사도 분석
                  </a>
                </li>
                <li id={`${tab == 2 ? 'current' : ''}`}>
                  <a
                    href="javascript:void(0)"
                    title="tab3"
                    className="px-4"
                    onClick={() => {
                      setTab(2);
                    }}
                  >
                    네트워크 분석
                  </a>
                </li>
              </ul>
              <div id="addlist" className="mt-5" style={{ minHeight: '303px' }}>
                {tab == 0 && (
                  <div id="tab1">
                    <p className="text-black/70">
                      결과출력 최대갯수를 설정합니다.
                    </p>
                    <ul className="mt-2 bg-stone-100/80 p-5 r5">
                      {[50, 100, 150, 200].map((value, index) => {
                        return (
                          <>
                            <li
                              className={`border-b pb-3.5 ${index != 0 ? 'mt-3.5' : ''
                                }`}
                            >
                              <label className="flex items-center check_box2">
                                <input
                                  type="radio"
                                  id="frequency"
                                  name="frequency"
                                  value={value}
                                  checked={
                                    analysisOption.maxFreqOutput == value
                                  }
                                  onChange={() => {
                                    setAnalysisOption({
                                      ...analysisOption,
                                      maxFreqOutput: value,
                                    });
                                  }}
                                  className="rounded-full"
                                />
                                <span className="ml-2 text-sm">
                                  {value}개 {value == 100 ? ' (기본값)' : ''}
                                </span>
                              </label>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {tab == 1 && (
                  <div id="tab2">
                    <p className="text-black/70">
                      결과출력 최대갯수를 설정합니다.
                    </p>
                    <ul className="mt-2 bg-stone-100/80 p-5 r5">
                      {[3, 4, 5, 6, 7].map((value, index) => {
                        return (
                          <>
                            <li
                              className={`border-b pb-3.5 ${index != 0 ? 'mt-3.5' : ''
                                }`}
                            >
                              <label className="flex items-center check_box2">
                                <input
                                  type="radio"
                                  id="similarity"
                                  name="similarity"
                                  value={value}
                                  checked={analysisOption.maxSimOutput == value}
                                  onChange={() => {
                                    setAnalysisOption({
                                      ...analysisOption,
                                      maxSimOutput: value,
                                    });
                                  }}
                                  className="rounded-full"
                                />
                                <span className="ml-2 text-sm">
                                  {value}개 {value == 5 ? ' (기본값)' : ''}
                                </span>
                              </label>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {tab == 2 && (
                  <div id="tab3">
                    <ul className="grid grid-cols-2 gap-4">
                      <li>
                        <p className="text-black/80">
                          결과출력 최대갯수를 설정합니다.
                        </p>
                        <ol className="mt-2 bg-stone-100/80 p-5 r5">
                          {[10, 20, 30, 50, 100].map((value, index) => {
                            return (
                              <>
                                <li
                                  className={`border-b pb-3.5 ${index != 0 ? 'mt-3.5' : ''
                                    }`}
                                >
                                  <label className="flex items-center check_box2">
                                    <input
                                      type="radio"
                                      id="maxNetOutput"
                                      name="maxNetOutput"
                                      value={value}
                                      checked={
                                        analysisOption.maxNetOutput == value
                                      }
                                      onChange={() => {
                                        setAnalysisOption({
                                          ...analysisOption,
                                          maxNetOutput: value,
                                        });
                                      }}
                                      className="rounded-full"
                                    />
                                    <span className="ml-2 text-sm">
                                      {value}개 {value == 50 ? ' (기본값)' : ''}
                                    </span>
                                  </label>
                                </li>
                              </>
                            );
                          })}
                        </ol>
                      </li>
                      <li>
                        <p className="text-black/50">
                          그래프 군집도 K값을 설정합니다.
                        </p>
                        <ol className="mt-2 bg-stone-100/80 p-5 r5">
                          {[0.3, 0.5, 0.7, 0.8, 0.9].map((value, index) => {
                            return (
                              <>
                                <li
                                  className={`border-b pb-3.5 ${index != 0 ? 'mt-3.5' : ''
                                    }`}
                                >
                                  <label className="flex items-center check_box2">
                                    <input
                                      type="radio"
                                      id="networkKValue"
                                      name="networkKValue"
                                      value={value}
                                      checked={
                                        analysisOption.networkKValue.toFixed(
                                          1,
                                        ) == value
                                      }
                                      onChange={() => {
                                        setAnalysisOption({
                                          ...analysisOption,
                                          networkKValue: value,
                                        });
                                      }}
                                      className="rounded-full"
                                    />
                                    <span className="ml-2 text-sm">
                                      {value}개{' '}
                                      {value == 0.7 ? ' (기본값)' : ''}
                                    </span>
                                  </label>
                                </li>
                              </>
                            );
                          })}
                        </ol>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>
            <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
              <a
                href="javascript:void(0)"
                className="btn_pop bg-neutral-300"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                취소
              </a>
              <a
                href="javascript:void(0)"
                className="btn_pop bg_orange3 text-white"
                onClick={() => {
                  if (projectStatus == 10) {
                    toast.error('분석 완료된 프로젝트입니다.');
                    setIsOpen(false);
                  } else {
                    saveOptions();
                  }
                }}
              >
                저장
              </a>
            </li>
          </ul>

          <div className="popbg btn_pop1"></div>
        </article>
      </>
    );
  } else return null;
}
