import { React, useState, useEffect, useRef, forwardRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale';
import { format } from 'date-fns';
import { setCurrentProject } from '../../store/modules/Main';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosClient from '../../lib/axiosClient';
import { toast } from 'react-toastify';

export default function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const currentRole = useSelector((state) => state.Main.currentProject.role);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly
      style={{ width: '450px' }}
      className="textarea1 p-3 r5 bg-slate-50"
    />
  ));

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [originalData, setOriginalData] = useState();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    axiosClient({
      method: 'get',
      url: '/api/project/getDetails',
      params: { projectId: projectId },
    })
      .then(async (result) => {
        const projectDetail = result.data.data;
        setOriginalData(projectDetail);
        if (projectDetail) {
          setValue('projectName', projectDetail.projectName);
          setValue('client', projectDetail.client);
          setValue('bizName', projectDetail.bizName);
          setValue('bidType', projectDetail.bidType);
          setValue('totalBudget', projectDetail.totalBudget);
          setValue('deadline', projectDetail.deadline);
          setValue('countMembers', projectDetail.countMembers);
          setValue('status', projectDetail.status);
          setValue('projectDesc', projectDetail.projectDesc);
        }
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  const updateProject = async () => {
    console.log(getValues());
    const currentDetailValue = getValues();
    const updates = { projectId: projectId };
    for (const key in currentDetailValue) {
      if (currentDetailValue[key] !== originalData[key]) {
        updates[key] = currentDetailValue[key];
      }
    }
    if (Object.keys(updates).length > 1) {
      try {
        await axios.post('/api/project/updateProjectDetails', updates);
        toast.success('수정이 완료됐습니다');
        dispatch(
          setCurrentProject({
            project: {
              title: getValues('projectName'),
              role: currentRole,
              status: getValues('status'),
            },
          }),
        );
        navigate(`/projects/projectDetails?id=${projectId}`);
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.info('변경사항이 없습니다.');
      return;
    }
  };

  const getDateValue = (value) => {
    if (typeof value === 'string') {
      return new Date(value);
    }
    return value instanceof Date ? value : new Date();
  };

  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">프로젝트 수정</span>
      </h3>
      <form
        ref={formRef}
        onSubmit={handleSubmit(updateProject)}
        style={{ overflowY: 'auto' }}
      >
        <article className="mt-10  contentsbox px-5">
          <ul className="grid grid-cols-1 gap-8">
            <li>
              <p className="text-lg font-bold w-28">
                프로젝트명
                <span className="font-black text-red-600">*</span>
              </p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="프로젝트명을 입력하세요."
                    style={{ width: '450px' }}
                    name="projectName"
                    {...register('projectName', {
                      required: '프로젝트명은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.projectName && <p>{errors.projectName.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">사업명</p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="사업명을 입력하세요."
                    style={{ width: '450px' }}
                    {...register('bizName', {
                      required: '사업명은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.bizName && <p>{errors.bizName.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">발주처</p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="발주처를 입력하세요."
                    style={{ width: '450px' }}
                    {...register('client', {
                      setValueAs: (value) => value.replaceAll(' ', ''),
                      required: '발주처는 필수입력 항목입니다.',
                    })}
                  />
                  {errors.client && <p>{errors.client.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">입찰방식</p>
              <p className="mt-1.5">
                <select
                  className="input_h45 f_15 px-3 r5 bg-slate-50"
                  style={{ width: '450px' }}
                  {...register('bidType', {
                    required: '입찰방식은 필수 선택 항목입니다.',
                  })}
                >
                  <option value="">선택하세요</option>
                  <option value="0">전자입찰</option>
                  <option value="1">직접입찰</option>
                </select>

                {errors.bidType && <p>{errors.bidType.message}</p>}
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">사업금액</p>
              <p className="mt-1.5">
                <input
                  className="textarea1 p-3 r5 bg-slate-50"
                  placeholder="사업금액을 입력하세요."
                  style={{ width: '450px' }}
                  rows="8"
                  {...register('totalBudget', {
                    required: '사업금액은 필수입력 항목입니다.',
                  })}
                />
                {errors.totalBudget && <p>{errors.totalBudget.message}</p>}
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">제출기한</p>
              <p className="mt-1.5">
                <Controller
                  name="deadline"
                  control={control}
                  defaultValue={getDateValue(getValues('deadline'))}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      locale={ko}
                      selected={getDateValue(value)}
                      onChange={(date) => {
                        const dateString = date
                          ? format(date, 'yyyy-MM-dd HH:mm:ss')
                          : '';
                        onChange(dateString);
                      }}
                      showTimeSelect
                      dateFormat="yyyy/MM/dd HH:mm:ss"
                      customInput={<CustomInput />}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <button
                            type="button"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          <span style={{ margin: '0 10px' }}>
                            {format(date, 'yyyy년 MM월', { locale: ko })}
                          </span>
                          <button
                            type="button"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    />
                  )}
                />

                {errors.deadline && <p>{errors.deadline.message}</p>}
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">프로젝트 상태</p>
              <p className="mt-1.5">
                <select
                  className="input_h45 f_15 px-3 r5 bg-slate-50"
                  style={{ width: '450px' }}
                  {...register('status', {
                    required: '프로젝트상태는 필수 선택 항목입니다.',
                  })}
                >
                  <option value="">선택하세요</option>
                  <option value="0">입찰문서 작성 중</option>
                  <option value="1">입찰문서 작성 완료</option>
                  <option value="10">분석 완료</option>
                </select>
                {errors.status && <p>{errors.status.message}</p>}
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">프로젝트 설명</p>
              <p className="mt-1.5">
                <textarea
                  className="textarea1 p-3 r5 bg-slate-50"
                  placeholder="프로젝트 설명을 입력하세요."
                  style={{ width: '450px' }}
                  rows="8"
                  {...register('projectDesc', {})}
                ></textarea>
                {errors.projectDesc && <p>{errors.projectDesc.message}</p>}
              </p>
            </li>
          </ul>
        </article>
      </form>
      <article className="mt-8 pt-8 shrink-0 border-t-2 flex justify-between">
        <p className="flex  gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_line r5 font-bold w-52"
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            취소
          </a>
        </p>
        <button
          onClick={(e) => {
            formRef.current.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            );
          }}
          className="btn_h45 btn_blue r5 font-bold w-52"
        >
          저장
        </button>
      </article>
    </>
  );
}
