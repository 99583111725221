import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AnalysisTargetModal from './AnalysisTargetModal';
import { useSearchParams } from 'react-router-dom';
import AiDifficultyAnalysis from './AnalysisResult/AiDifficultyAnalysis';
import BasicAnalysis from '../Detail/AnalysisResult/BasicAnalysis';
import OntologyAnalysis from '../Detail/AnalysisResult/OntologyAnalysis';
import GuidelineSearch from '../Detail/AnalysisResult/GuideLineSearch';
import { io } from 'socket.io-client';
import axiosClient from '../../../lib/axiosClient';
import { toast } from 'react-toastify';

const AnalysisResult = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = parseInt(searchParams.get('history'));
  const [activeTab, setActiveTab] = useState('basic'); // 탭 메뉴
  const [analysisOption, setAnalysisOption] = useState({});
  const [modelStatus, setModelStatus] = useState(false);
  const prevModelStatus = useRef(null)
  const topTab = [
    { id: 'basic', title: '기본분석' },
    { id: 'ai', title: 'AI난이도 분석' },
    { id: 'ontology', title: '온톨로지 분석' },
    { id: 'guideline', title: '시행지침검색' },
  ];
  const [topTabIndex, setTopTabIndex] = useState(0);

  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );

  const [analysisInfo, setAnalysisInfo] = useState({});

  const getAnalysisData = async () => {
    const response = await axios.post('/api/analysis/getAnalysisData', {
      historyId,
      projectId,
    });
    if (response.data.status == 'OK') {
      setAnalysisInfo(response.data.data.result);
      setAnalysisOption(response.data.data.options);

    }
  };

  // AI분석 이력 삭제
  const deleteAiAnalysedHisotry = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/deleteAiAnalysedHisotry',
      data: {
        historyId: historyId,
      },
    })
      .then(async (result) => {
        if (result.data.success) {
          navigate(`/projects/aiAnalysis?id=${projectId}`);
        }
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  useEffect(() => {
    getAnalysisData();
  }, []);

  const reAnalysis = async () => {
    if (_.filter(completedTap, (v) => v == 1).length == 0 && modelStatus != 1) {
      if (!confirm("재분석을 실행 할까요?")) {
        return;
      }

      let params = {};
      let search = [...searchParams.entries()];
      search.forEach((v) => {
        params = {
          ...params,
          [v[0]]: v[1],
        };
      });
      const response = await axios.post('/api/analysis/reAnalysis', {
        projectId,
        historyId,
      });
      const aiParams = response.data.data.aiAnalysisParams;
      axios.post('/api/analysis/runAiAnalysis', { ...aiParams });
      setSearchParams(
        { ...params, history: aiParams.historyId },
        { replace: true, preventScrollReset: true },
      );
    }
  };

  const moveNext = () => {
    let moveIndex = 0;
    console.log('🚀 ~ moveNext ~ topTabIndex:', topTabIndex);
    if (topTabIndex == topTab.length - 1) {
      //마지막인덱스일때 처음으로돌아감
      moveIndex = 0;
    } else {
      moveIndex = topTabIndex + 1;
    }
    setTopTabIndex(moveIndex);
    setActiveTab(topTab[moveIndex].id);
  };

  const [analysisResult, setAnalysisResult] = useState({});
  useEffect(() => {
    // 웹소켓 연결 생성  167.179.75.7
    //149.28.30.105
    const socket = io('http://149.28.30.105:3030/checkAnalysisStatus');

    socket.on('connect', () => {
      console.log('Connected to server');

      // 연결 후 배열 또는 객체를 서버로 전송
      const data = [historyId];
      console.log('🚀 ~ socket.on ~ data:', data);
      socket.emit('AnalysisIdList', data);
    });

    socket.on('analysisStatus', (data) => {
      let result = _.find(data, { history_id: historyId });
      console.log('🚀 ~ socket.on ~ data:', data);
      setAnalysisResult(result);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const [completedTap, setCompletedTap] = useState({
    basic: 0,
    ai: 0,
    ontology: 0,
  });
  useEffect(() => {
    const analysisStatus = analysisResult || analysisInfo;
    const tabStatus = updateIsCompletedTap(analysisStatus);
    setModelStatus(analysisStatus.status);
    setCompletedTap(tabStatus);
  }, [analysisInfo, analysisResult]);

  useEffect(() => {
    if (modelStatus == 1) {
    } else {
      if (prevModelStatus.current == 1 && modelStatus == 2) {
        //학습완료 재분석시작.
        alert("모델학습이 완료되었습니다. 재분석을 시작합니다.")
        reAnalysis();
      }

    }
    prevModelStatus.current = modelStatus
  }, [modelStatus])

  // 탭 메뉴 값 관리

  // -1:실패 0:분석대기 1:분석중 2:분석완료
  const updateIsCompletedTap = (analysisStatus) => {
    const completedTap = {
      basic: 0,
      ai: 0,
      ontology: 0,
    };
    if (
      analysisStatus.faStep === 2 &&
      analysisStatus.naStep === 2 &&
      analysisStatus.saStep === 2
    ) {
      completedTap.basic = 2;
    } else if (
      analysisStatus.faStep === -1 ||
      analysisStatus.naStep === -1 ||
      analysisStatus.saStep === -1
    ) {
      completedTap.basic = -1;
    } else {
      completedTap.basic = 1;
    }

    if (analysisStatus.daStep === 2) {
      completedTap.ai = 2;
    } else if (analysisStatus.daStep === -1) {
      completedTap.ai = -1;
    } else {
      completedTap.ai = 1;
    }
    if (
      analysisStatus.daStep === 2 &&
      analysisStatus.obdaStep === 2 &&
      analysisStatus.obgrsStep === 2
    ) {
      completedTap.ontology = 2;
    } else if (
      analysisStatus.obdaStep === -1 ||
      analysisStatus.obgrsStep === -1 ||
      analysisStatus.daStep === -1
    ) {
      completedTap.ontology = -1;
    } else {
      completedTap.ontology = 1;
    }
    console.log("🚀 ~ updateIsCompletedTap ~ completedTap:", analysisStatus, completedTap)
    return completedTap;
  };

  // 탭 메뉴 값에 따른 화면 처리
  const handleCompletedTap = (index, id, title) => {
    if (completedTap[id] != undefined) {
      if (completedTap[id] == 2) {
        return (
          <>
            <span className="num">{index + 1}</span>
            {title}
            <span className="ml-2 state_icon">
              <img src="/images/step_tabicon2.png" alt="성공" width="22" />
            </span>
          </>
        );
      } else if (completedTap[id] == 1) {
        return (
          <>
            {index + 1}. {title}
            <span className="ml-2">
              <img
                src="/images/step_tabicon3.png"
                className="spinner"
                alt="로딩"
                width="20"
              />
            </span>
          </>
        );
      } else if (completedTap[id] == -1)
        return (
          <>
            <span className="num">{index + 1}</span>
            {title}
            <span className="ml-2 state_icon">
              <img src="/images/step_tabicon1.png" alt="실패" width="22" />
            </span>
          </>
        );
    } else {
      return (
        <>
          <span className="num">{index + 1}</span>
          {title}
          <span className="ml-2 state_icon"></span>
        </>
      );
    }
  };

  return (
    <>
      {/* <section className="mt-2.5 containerbox px-14 py-8"> */}
      {
        modelStatus == 1 &&
        <ul class="pointbox p-2">
          <li class="img mr-2.5">
            <img src="/images/ai_point.gif" alt="" />
          </li>
          <li class="text-lg text-white font-bold">
            AI 모델 학습이 진행 중입니다. 학습이 완료되면 다시 서비스 이용 가능합니다.
          </li>
        </ul>
      }
      <ul class="mt-5 depth1_step ">
        {topTab.map((tab, index) => {
          return (
            <>
              <li
                className="ml-3"
                onClick={() => {
                  setActiveTab(tab.id);
                  setTopTabIndex(index);
                }}
              >
                <a
                  href="javascript:void(0)"
                  className={`${activeTab == tab.id ? 'active' : ''}`}
                >
                  <p class="left transition-all duration-500 "></p>
                  <p class={`tt transition-all  duration-500 ease-in-out ${activeTab == tab.id ? 'w-[720px]' : 'w-48'}`}>
                    {handleCompletedTap(index, tab.id, tab.title)}
                  </p>
                  <p class="right transition-all duration-500 "></p>
                </a>
              </li>
            </>
          );
        })}

        {/* <div className="flex flex-end ml-auto">
          <a
            href="javascript:void(0)"
            className="btn_h35 px-4 r5 border border-slate-300 lg:hover:border-slate-800"
            onClick={() => setIsModalOpen(true)}
          >
            <img
              src="/images/icon_select1.png"
              alt="icon"
              width="13"
              className="opacity-40"
            />

            <span className="ml-2 text-sm">분석대상</span>
          </a>

          <a
            href="javascript:void(0)"
            className="ml-1 btn_h35 px-4 r5 border border-slate-300 lg:hover:border-slate-800"
            onClick={(e) => {
              if (projectStatus == 10) {
                toast.error('분석 완료된 프로젝트입니다.');
                return;
              }
              if (confirm('삭제하시겠습니까?')) deleteAiAnalysedHisotry();
            }}
          >
            <img
              src="/images/icon_del1.png"
              alt="icon"
              width="13"
              className="opacity-40"
            />
            <span className="ml-2 text-sm">분석결과 삭제</span>
          </a>
        </div> */}
      </ul>

      <article className="mt-10 contentsbox flex">
        {/* 기본분석 화면 */}
        {activeTab == 'basic' && (
          <BasicAnalysis
            analysisResult={analysisResult}
            analysisInfo={analysisInfo}
            reAnalysis={reAnalysis}
          />
        )}

        {activeTab == 'ai' && (
          <AiDifficultyAnalysis
            analysisResult={analysisResult}
            analysisInfo={analysisInfo}
            reAnalysis={reAnalysis}
            analysisOption={analysisOption}
            setAnalysisOption={setAnalysisOption}
          />
        )}
        {activeTab == 'ontology' && (
          <OntologyAnalysis
            reAnalysis={reAnalysis}
            analysisResult={analysisResult}
            analysisInfo={analysisInfo}
            analysisOption={analysisOption}
          />
        )}

        {/* 시행지침 검색 화면 */}
        {activeTab == 'guideline' && <GuidelineSearch />}
      </article>

      <article className="mt-8 pt-8 shrink-0 border-t-2 flex justify-end gap-2">
        <p className="flex_center gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_blue r5 font-bold w-52"
            onClick={() => {
              if (
                projectStatus == 10 ||
                _.filter(completedTap, (v) => v == 1).length > 0
              ) {
                toast.error('분석 완료된 프로젝트입니다.');
                return;
              }
              if (!confirm("재분석을 실행 할까요?")) {
                return;
              }
              reAnalysis();
            }}
          >
            재분석
          </a>
        </p>
        <a
          href="javascript:void(0)"
          className="btn_h45 btn_gray  r5 font-bold w-52"
          onClick={moveNext}
        >
          다음단계로
        </a>
      </article>
      {isModalOpen && (
        <AnalysisTargetModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          docId={analysisOption.docId}
        />
      )}
    </>
  );
};
export default AnalysisResult;
