import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function AiDifficultyAnalysisOptionPop({
  setIsOpen,
  classificationMethod,
  setClassificationMethod,
}) {
  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '650px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">분류방법 설정 </span>
            </p>
            <p className="mt-1 text-black/60">난이도 분류 방법을 설정합니다.</p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <div id="addlist">
              <div id="tab1">
                <ul className="mt-2 bg-stone-100/80 p-5 r5">
                  <li className="border-b pb-3.5">
                    <label className="flex items-center check_box2">
                      <input
                        type="radio"
                        name="1"
                        className="rounded-full"
                        checked={
                          classificationMethod == 'HIGH_MID_ROW' ? true : false
                        }
                        onChange={(e) => {
                          setClassificationMethod('HIGH_MID_ROW');
                        }}
                      />
                      <span className="ml-2 text-sm">상, 중, 하 (기본값)</span>
                    </label>
                  </li>
                  <li className="mt-3.5 flex items-center">
                    <label className="flex items-center check_box2">
                      <input
                        type="radio"
                        name="1"
                        className="rounded-full"
                        checked={
                          classificationMethod == 'HIGH_ROW' ? true : false
                        }
                        onChange={(e) => {
                          setClassificationMethod('HIGH_ROW');
                        }}
                      />
                      <span className="ml-2 text-sm">상, 하</span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              저장
            </a>
          </li>
        </ul>

        <div className="popbg btn_pop1"></div>
      </article>
    </>
  );
}
