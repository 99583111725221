import { React, useEffect, useState } from 'react';
import BasicAnalysisOptionPop from './BasicAnalysisOptionPop';
import BarChart from '../../../Chart/BarChart';
import NetWorkChart from '../../../Chart/NetworkChart';
import WordCloudChart from '../../../Chart/WordCloud';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import useLoading from '../../../../hooks/useLoading';
import axiosClient from '../../../../lib/axiosClient';
import { toast } from 'react-toastify';

export default function BasicAnalysis({
  analysisResult,
  analysisInfo,
  reAnalysis,
}) {
  const [isOpen, setIsOpen] = useState();
  const [faResult, setFaResult] = useState(undefined);
  const [saResult, setSaResult] = useState(undefined);
  const [naResult, setNaResult] = useState(undefined);
  const [networkK, setNetworkK] = useState(0.7);
  const [searchParams, setSearchParams] = useSearchParams();
  const historyId = parseInt(searchParams.get('history'));
  const projectId = parseInt(searchParams.get('id'));
  const getAnalysisResultData = async () => {
    axiosClient({
      method: 'post',
      url: '/api/analysis/getAnalysisResultData',
      data: {
        historyId,
        projectId,
      },
    }).then(async (response) => {
      if (response.data.status == 'OK') {
        setFaResult(response.data.data.data.faResult);
        setSaResult(response.data.data.data.saResult);
        setNaResult(response.data.data.data.naResult);
        setNetworkK(response.data.data.networkK);
      }
    });
  };

  useEffect(() => {
    getAnalysisResultData();
  }, [analysisResult]);

  const ResultTable = ({ step }) => {
    if (step == 'faStep') {
      return (
        <>
          <table className="w-4/12">
            <colgroup>
              <col width="100" />
              <col width="*" />
              <col width="*" />
            </colgroup>
            <thead className="thead2">
              <tr className="f_15">
                <th className="py-2 px-2.5">순위</th>
                <th className="px-2.5">단체</th>
                <th className="px-2.5">빈도</th>
              </tr>
            </thead>
            <tbody className="tbody1 text-sm text-center">
              {faResult?.slice(0, 5)?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td className="p-2.5">{index + 1}</td>
                      <td className="p-2.5">{result.word}</td>
                      <td className="p-2.5">
                        <span className="point_bar">
                          {result.frequency.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          <table className="w-4/12">
            <colgroup>
              <col width="100" />
              <col width="*" />
              <col width="*" />
            </colgroup>
            <thead className="thead2">
              <tr className="f_15">
                <th className="py-2 px-2.5">순위</th>
                <th className="px-2.5">단체</th>
                <th className="px-2.5">빈도</th>
              </tr>
            </thead>
            <tbody className="tbody1 text-sm text-center">
              {faResult?.slice(5, 10)?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td className="p-2.5">{index + 1 + 5}</td>
                      <td className="p-2.5">{result.word}</td>
                      <td className="p-2.5">
                        <span className="point_bar">
                          {result.frequency.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
    } else {
      return (
        <>
          <table class="w-4/12">
            <colgroup>
              <col width="100" />
              <col width="*" />
            </colgroup>
            <thead class="thead2">
              <tr class="f_15">
                <th class="py-2 px-2.5">순위</th>
                <th class="px-2.5">문서형</th>
              </tr>
            </thead>
            <tbody class="tbody1 text-sm text-center">
              {saResult?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td class="p-2.5">{index + 1}</td>
                      <td class="p-2.5">
                        <a
                          href="#"
                          download
                          onClick={(e) => {
                            e.preventDefault();
                            toast.info(
                              `${result.document} 파일이 다운로드됩니다.`,
                            );
                          }}
                        >
                          {result.document}
                        </a>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }
  };

  const StatusCell = ({ step, title, desc }) => {
    const status = analysisResult?.[step] || analysisInfo?.[step];
    const { LoadingElement } = useLoading({
      init: status == 1 ? true : false,
      size: 14,
    });
    const stepList = ['faStep', 'saStep', 'naStep'];
    return (
      <>
        <dl className="ml-4 mt-7 relative" id={step}>
          {status == -1 && <div class="loading"></div>}
          {<LoadingElement />}
          <dt className="flex items-center">
            <span className="numbox1 bg_orange3 text-white mr-2">
              {_.indexOf(stepList, step) + 1}
            </span>
            <span className="flex flex-col">
              <span className="text-lg font-bold">{title}</span>

            </span>
          </dt>
          <dd className="mt-3 flex flex-col gap-2 border-2 p-4">
            {step == 'faStep' ? (
              <>
                - 입찰문서 내 빈도수 분석을 통해 주요 단어를 추출한 결과입니다.<br />
                - 빈도수 기반 주요 용어 : {faResult && faResult.slice(0, 5).map((v, i) => (i + 1) + ") " + v.word + "  ")}
              </>
            ) : step == 'saStep' ? (
              <>
                - 과거 프로젝트 입찰문서와의 비교를 통해 가장 유사했던 프로젝트의 목록을 제공합니다.<br />
                - 현 프로젝트와 가장 유사한 과거의 프로젝트는 <br />
                <div className="pl-4">
                  {saResult && saResult.slice(0, 5).map((v, i) => {
                    return <>
                      {i + 1}) {v.document} <br />
                    </>
                  })}
                </div>

              </>
            ) : step == 'naStep' ? (
              <>
                - 입찰문서 내 포함된 단어들간의 연결관계를 분석하여 주요 연관단어를 제공합니다.<br />
                - {naResult && naResult.length >= 1 ? `${_.first(naResult).term1}, ${_.first(naResult).term2}이(가) 가장 연관성이 높으며, ` : ``}
                가장 많은 용어"{faResult && faResult.length >= 1 ? `${_.first(faResult).word}` : ``}"와(과) 연관성이 높은 용어는 "{
                  faResult && faResult.length >= 1 ? (
                    _.chain(naResult)
                      .filter(item => item.term1 == (_.first(faResult).word) || item.term2 == (_.first(faResult).word))
                      .map(item => ({
                        term: item.term1 == (_.first(faResult).word) ? item.term2 : item.term1,
                        freq: parseInt(item.freq)
                      }))
                      .orderBy('freq', 'desc')
                      .head()
                      .get('term')
                      .value()
                  ) : (null)
                }" 입니다
              </>
            ) : null}

          </dd>
          <dd className="mt-3 flex gap-4 justify-center">
            {step != 'naStep' && <ResultTable step={step} />}

            {step == 'faStep' ? (
              <div className="w-96 border flex-center h-[350px] flex-col relative">
                {faResult && <WordCloudChart data={faResult} style={{}} />}
              </div>
            ) : step == 'saStep' ? (
              <div className="w-8/12 border flex-center h-[350px] flex-col relative">
                {saResult && <BarChart data={saResult} />}
              </div>
            ) : step == 'naStep' ? (
              <div className="w-96 border flex-center h-[350px] flex-col relative">
                {naResult && (
                  <NetWorkChart data={naResult} crowd={networkK} style={{}} />
                )}
              </div>
            ) : null}
            {step == 'naStep' && <>
              <table className="w-4/12">
                <colgroup>
                  <col width="100" />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <thead className="thead2">
                  <tr className="f_15">
                    <th className="py-2 px-2.5 ">순위</th>
                    <th className="" colSpan={2}>강한 연결관계 목록</th>
                  </tr>
                </thead>
                <tbody className="tbody1 text-sm text-center">
                  {naResult?.slice(0, 5)?.map((result, index) => {
                    return (
                      <>
                        <tr>
                          <td className="p-2.5">{index + 1}</td>
                          <td className="p-2.5">{result.term1}</td>
                          <td className="p-2.5">{result.term2}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </>}

          </dd>
        </dl>
      </>
    );
  };

  return (
    <>
      <div className="ml-12 conbox" style={{ overflowX: 'hidden' }}>
        <div className="flex items-center justify-between">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">
              입찰문서 기본 분석 결과
            </span>
          </h4>
          <p className="flex gap-1">
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
              onClick={reAnalysis}
            >
              재분석
            </a>
            <a
              href="http://149.28.30.105/aiAnalysis.pdf"
              target='blank'
              download="aiAnalysis.pdf"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
            >
              다운로드
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              전문가 모드
            </a>
          </p>
        </div>
        <StatusCell
          key="faStep"
          step="faStep"
          title="빈도수 분석 결과"
        />
        <StatusCell
          key="saStep"
          step="saStep"
          title="유사도 분석 결과"
          desc="유사도 분석 결과 부연 설명"
        />
        <StatusCell
          key="naStep"
          step="naStep"
          title="네트워크 분석 결과"
          desc="네트워크 분석 결과 부연 설명"
        />
      </div>

      {isOpen && (
        <BasicAnalysisOptionPop isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
}
