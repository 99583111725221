import { useState, useEffect } from 'react';
import UserWord from './UserWord';
import UserStopWord from './UserStopWord';
export default function OptionPop({
  projectId,
  setDocument,
  document,
  isOpen,
  setIsOpen,
  setOptionChanged,
  rePreprocessDocument,
}) {
  const [tab, setTab] = useState(0);
  const [userWordData, setUserWordData] = useState([]);
  const [stopWordData, setStopWordData] = useState([]);
  const [deletedUserWordList, setDeletedUserWordList] = useState([]);
  const [deletedUserStopWordList, setDeletedUserStopWordList] = useState([]);
  const [userWordImported, setUserWordImported] = useState(0);
  const [userStopWordImported, setUserStopWordImported] = useState(0);

  const save = async () => {
    const deletedUserWordIds = userWordData
      .filter((data) => data.word.trim() === '' || data.word === null)
      .map((data) => data.wordId);

    const deletedUserStopWordIds = stopWordData
      .filter((data) => data.stopword.trim() === '' || data.stopword === null)
      .map((data) => data.stopwordId);

    const preprocessOptions = {
      projectId,
      document,
      userWordList: userWordData.filter(
        (data) => data.word.trim() !== '' && data.word !== null,
      ),
      userStopWordList: stopWordData.filter(
        (data) => data.stopword.trim() !== '' && data.stopword !== null,
      ),
      deletedUserWordList: [...deletedUserWordList, ...deletedUserWordIds],
      deletedUserStopWordList: [
        ...deletedUserStopWordList,
        ...deletedUserStopWordIds,
      ],
      userWordImported,
      userStopWordImported,
    };

    console.log('🚀 ~ save ~ preprocessOptions:', preprocessOptions);
    setIsOpen(false);
    setTab(0);
    setOptionChanged(true);
    await rePreprocessDocument(preprocessOptions);
  };

  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '650px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">전처리 세부옵션 설정 </span>
            </p>
            <p className="mt-1 text-black/60">
              전처리 시 사용할 세부 옵셜을 설정하세요.
            </p>
            <a href="javascript:void(0)" className="close1 btn_pop1">
              <img
                src="/images/icon_close1.png"
                alt="close"
                width="33"
                onClick={() => {
                  setIsOpen(false);
                  setTab(0);
                }}
              />
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <ul id="tabs" className="gap-1">
              <li id={`${tab == 0 ? 'current' : ''}`} onClick={() => setTab(0)}>
                <a href="#" title="tab2" className="px-4">
                  용어사전
                </a>
              </li>
              <li id={`${tab == 1 ? 'current' : ''}`} onClick={() => setTab(1)}>
                <a href="#" title="tab3" className="px-4">
                  불용어사전
                </a>
              </li>
            </ul>
            <div id="addlist" className="mt-5">
              <UserWord
                document={document}
                setUserWordData={setUserWordData}
                setDeletedUserWordList={setDeletedUserWordList}
                setUserWordImported={setUserWordImported}
                tab={tab}
              />
              <UserStopWord
                document={document}
                setUserStopWordData={setStopWordData}
                setDeletedUserStopWordList={setDeletedUserStopWordList}
                setUserStopWordImported={setUserStopWordImported}
                tab={tab}
              />
            </div>
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
                setTab(0);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={save}
            >
              재분석
            </a>
          </li>
        </ul>

        <div className="popbg btn_pop1"></div>
      </article>
    </>
  );
}
