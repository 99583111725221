import { useState, useEffect } from 'react';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
export function useExcel({
  dataList,
  columnIdList,
  setDataList,
  setTotalCount,
}) {
  const [fileName, setFileName] = useState('excel_download');
  // 데이터 필터링 및 업로드
  const excelUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    // 확장자명 가져오기
    const fileName = file.name;
    setFileName(fileName);
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtension = fileName.substring(lastDotIndex + 1).toLowerCase();
    const extension = ['xlsx', 'csv'];
    if (
      _.indexOf(extension, fileExtension) == -1 ||
      lastDotIndex === -1 ||
      lastDotIndex === fileName.length - 1
    ) {
      setDataList([]);
      toast.error('엑셀 파일(.xlsx)만 업로드할 수 있습니다.');

      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      if (fileExtension === 'xlsx') {
        // Handle xlsx file
        const workbook = new Excel.Workbook();
        let firstRow = [];
        try {
          await workbook.xlsx.load(arrayBuffer);

          const data = [];
          workbook.worksheets.forEach((worksheet) => {
            let isFirstRow = true;

            worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
              const rowValues = row.values;
              // Use the first row as headers
              if (isFirstRow) {
                isFirstRow = false;
                firstRow = rowValues;
              } else {
                data.push(rowValues);
              }
            });
          });

          setTotalCount(data.length);
          const result = data.map((row) => {
            const obj = {};
            columnIdList.forEach((column) => {
              const header = column.header;
              const headerIndex = _.indexOf(firstRow, header);
              if (row[headerIndex] !== undefined) {
                obj[column.id] = row[headerIndex];
              }
            });
            return obj;
          });
          setDataList(result);
          console.log('🚀 ~ reader.onload= ~ result:', result);
        } catch (error) {
          console.error('오류:', error);
        }
      } else if (fileExtension === 'csv') {
        // Handle csv file
        Papa.parse(file, {
          encoding: 'UTF-8',
          complete: (results) => {
            const data = results.data;
            console.log('🚀 ~ reader.onload= ~ data:', data);
            let firstRow = data[0];
            const remainingData = data.slice(1);

            setTotalCount(remainingData.length);
            const result = remainingData.map((row) => {
              const obj = {};
              columnIdList.forEach((column) => {
                const header = column.header;
                const headerIndex = firstRow.indexOf(header);
                if (row[headerIndex] !== undefined) {
                  obj[column.id] = row[headerIndex];
                }
              });
              return obj;
            });
            setDataList(result);
            console.log('🚀 ~ CSV parse result:', result);
          },
          error: (error) => {
            console.error('오류:', error);
          },
        });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // 엑셀 파일 다운로드
  const excelDownload = async (downloadName = fileName, list = dataList) => {
    // TH width, 단위는 cell의 width나 height 단위는 픽셀이 아닌 엑셀의 너비 기준이다.
    const headerWidths = [24, 24, 24];

    try {
      const columnIds = columnIdList?.map((column) => column.id);

      let filteredRows = [];
      let columns = [];

      if (list && list.length > 0) {
        filteredRows = list.map((row) => {
          const filteredRow = {};
          columnIds.forEach((id) => {
            if (row.hasOwnProperty(id)) {
              filteredRow[id] = row[id];
            }
          });
          return filteredRow;
        });

        // 최대 키 개수를 가진 객체 찾기
        let maxKeysObject = filteredRows[0];
        filteredRows.forEach((data) => {
          if (Object.keys(data).length > Object.keys(maxKeysObject).length) {
            maxKeysObject = data;
          }
        });

        columns = Object.keys(maxKeysObject);
      } else {
        // dataList가 비어있을 경우 컬럼 ID를 사용
        columns = columnIds;
      }

      // 컬럼명 id에 맞게 변환
      const changedColumns = columns.map((column) => {
        const match = columnIdList.find((obj) => obj.id === column);
        return match ? match.header : column;
      });

      // workbook 생성
      const wb = new Excel.Workbook();
      // sheet 생성
      const sheet = wb.addWorksheet('문장 추출 내역');

      // 상단 헤더(TH) 추가
      const headerRow = sheet.addRow(changedColumns);
      headerRow.height = 30.75;
      headerRow.eachCell((cell, colNum) => {
        styleHeaderCell(cell);
        sheet.getColumn(colNum).width = headerWidths[colNum - 1];
      });

      // 데이터가 있을 경우에만 데이터 행 추가
      if (filteredRows.length > 0) {
        filteredRows.forEach((row) => {
          const rowDatas = columns.map((key) => row[key]);
          const appendRow = sheet.addRow(rowDatas);

          appendRow.eachCell((cell, colNum) => {
            styleDataCell(cell);
            cell.numFmt = '0,000';
          });
        });
      }

      // 파일 생성
      const fileData = await wb.xlsx.writeBuffer();
      const blob = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, downloadName);
    } catch (error) {
      console.error(error);
    }
  };

  // 헤더 스타일
  const styleHeaderCell = (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffebebeb' },
    };
    cell.border = {
      bottom: { style: 'thin', color: { argb: 'ff000000' } },
      right: { style: 'thin', color: { argb: 'ff000000' } },
    };
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true,
      color: { argb: 'ff252525' },
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
  };

  // 데이터 셀 스타일
  const styleDataCell = (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffffff' },
    };
    cell.border = {
      bottom: { style: 'thin', color: { argb: 'ff000000' } },
      right: { style: 'thin', color: { argb: 'ff000000' } },
    };
    cell.font = {
      name: 'Arial',
      size: 10,
      color: { argb: 'ff252525' },
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
  };

  return {
    fileName,
    setFileName,
    excelUpload,
    excelDownload,
  };
}

export default useExcel;
