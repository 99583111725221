import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export default function OntologyDocPop({ setIsOpen, projectStatus }) {
  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '650px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-1">
                <img src="/images/icon_select1.png" width="25" />
              </span>
              <span className="tit">문서 선택 </span>
            </p>
            <p className="mt-1 text-black/60">일반과업내용서.hwp</p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop2"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <p className="mt-4 flex items-center gap-1">
              <label>
                <input
                  type="text"
                  className="input_h45 f_15 px-3 r5 bg-slate-50"
                  placeholder="검색어를 입력해주세요."
                  style={{ width: '350px' }}
                />
              </label>
              <a
                href="javascript:void(0)"
                className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
              >
                <img
                  src="/images/icon_search1.png"
                  alt="돋보기"
                  width="13"
                  className="shrink-0 imgwhite"
                />
                <span className="ml-1 text-sm text-white font-bold ">검색</span>
              </a>
            </p>
            <table className="mt-3 w-full">
              <colgroup>
                <col width="120" />
                <col width="*" />
                <col width="100" />
              </colgroup>
              <thead className="thead1 f_15">
                <tr className="text-center">
                  <th className="px-3">
                    <label className="check_box1 flex_center">
                      <input type="checkbox" className="r4" name="1" />
                      <span className="ml-2 text-indigo-800">번호</span>
                    </label>
                  </th>
                  <th className="px-3">문장</th>
                  <th className="px-3">난이도</th>
                </tr>
              </thead>
              <tbody className="tbody1 text-sm text-center">
                <tr>
                  <td className="py-2.5 px-3 ">
                    <label className="check_box1 flex_center">
                      <input type="checkbox" className="r4" name="1" />
                      <span className="ml-2 text-sm">140</span>
                    </label>
                  </td>
                  <td className="p-2.5 px-3 text-left">
                    교통수요예측은 「교통시설 투자평가지침」에 따라 수요예측 각
                    단계별(통행발생, 통행분포, 수단선택, 통행배정)로 검토한다.
                  </td>
                  <td className="py-1.5 px-3">상</td>
                </tr>
                <tr>
                  <td className="py-2.5 px-3 text-left">
                    <label className="check_box1 flex_center">
                      <input type="checkbox" className="r4" name="1" />
                      <span className="ml-2 text-sm">141</span>
                    </label>
                  </td>
                  <td className="p-2.5 px-3 text-left">
                    영향권을 설정하기 위한 방법으로 Selected link 분석과 O/D
                    기준 통행량비율(PV), 구간교통량 변화율(RV), 구간교통량
                    변화량(DV)을 각각 적용하여 검토하며 최종 영향권 설정결과를
                    직접영향권과 간접영향권으로 구분하여 도면으로 표현하여
                    제시한다.
                  </td>
                  <td className="py-1.5 px-3">중</td>
                </tr>
              </tbody>
            </table>
            <ul className="mt-4 page gap-1">
              <li className="deactivation ">
                <img
                  src="/images/page_prev.png"
                  width="19"
                  className="opacity-30"
                />
              </li>
              <li className="active">1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>
                <img
                  src=" /images/page_next.png"
                  width="19"
                  className="opacity-30"
                />
              </li>
            </ul>
          </li>
          <li className="mt-2 pb-8  shrink-0 flex_center gap-1 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300 px-16 r5"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white px-16 r5"
              onClick={() => {
                if (projectStatus == 10) {
                  toast.error('분석 완료된 프로젝트입니다.');
                  return;
                }
                setIsOpen(false);
              }}
            >
              추가
            </a>
          </li>
        </ul>

        <div className="popbg btn_pop2"></div>
      </article>
    </>
  );
}
