import { useState, useEffect, useRef } from 'react';
import useTableControl from '../../hooks/useTableControl';
import useExcel from '../../hooks/useExcelProcessor';
import ReactTable_CS from '../Components/ReactTable_CS';
import axiosClient from '../../lib/axiosClient';
export default function UserStopWord({
  document,
  setUserStopWordData,
  setDeletedUserStopWordList,
  setUserStopWordImported,
  tab,
}) {
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);

  const [userStopWordList, setUserStopWordList] = useState(undefined);
  const { excelDownload, excelUpload, fileName } = useExcel({
    dataList: userStopWordList,
    setDataList: setUserStopWordList,
    setTotalCount: setTotalCount,
    columnIdList: columns,
  });
  const fileInputRef = useRef(null);

  // 컬럼명 설정
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
      },
      {
        header: '단어',
        id: 'stopword',
        editable: true,
      },
      {
        header: '삭제',
        id: 'delete',
        cellRender: (row, meta) => {
          return (
            <>
              <button
                onClick={() => {
                  meta.deleteData(row);
                  setDeletedUserStopWordList((old) => [...old, row.stopwordId]);
                  setTotalCount((prev) => prev - 1);
                }}
              >
                삭제
              </button>
            </>
          );
        },
      },
    ]);
  }, []);

  const getUserStopWordList = async () => {
    const response = await axiosClient.post(
      '/api/analysis/getUserStopWordList',
      {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: parseInt(document.projectId),
        },
      },
    );
    if (response.data.status == 'OK') {
      setUserStopWordList(response.data.data.rows);
      setTotalCount(response.data.data.totalCount);
    }
  };

  useEffect(() => {
    getUserStopWordList();
  }, [sortOption]);

  useEffect(() => {
    setUserStopWordData(userStopWordList);
  }, [userStopWordList]);

  const addRow = () => {
    const targetId = columns.find((column) => column.header == '번호').id;
    if (targetId == 'reverse') {
      setUserStopWordList([
        { stopwordId: 0, stopword: '' },
        ...userStopWordList,
      ]);
    } else {
      setUserStopWordList([
        ...userStopWordList,
        { stopwordId: 0, stopword: '' },
      ]);
    }
    setTotalCount((prev) => prev + 1);
  };

  if (tab == 1) {
    return (
      <>
        <div id="tab3" style={{ minHeight: '430px' }}>
          <dl>
            <dt class="relative">
              <div class="flex items-center justify-between">
                <p className="leading-6 grow">
                  <span className="font-bold"> 사용자 정의 용어사전</span>
                  <br />
                  <span className="text-sm text-black/60">
                    추가할 용어를 입력하세요.
                  </span>
                </p>
                <div class="flex gap-1">
                  <label
                    htmlFor="file-upload"
                    className="btn_h35 r5 px-4 bg-slate-500 text-white cursor-pointer"
                    style={{
                      background: '#0f8107',
                    }}
                  >
                    엑셀 업로드
                  </label>
                  <input
                    id="file-upload"
                    className="hidden"
                    type="file"
                    accept=".xlsx"
                    onChange={(e) => {
                      setUserStopWordImported(1);
                      excelUpload(e);
                      e.target.value = '';
                    }}
                  />
                  <a
                    href="javascript:void(0)"
                    class="btn_h35 r5 px-4"
                    style={{
                      background: '#0f8107',
                      color: '#fff',
                    }}
                    onClick={() => { excelDownload() }}
                  >
                    엑셀 추출
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn_h35 r5 px-4 "
                    style={{
                      background: '#0f8107',
                      color: '#fff',
                    }}
                    onClick={addRow}
                  >
                    단어추가
                  </a>
                </div>
              </div>
            </dt>

            <dd>
              {userStopWordList && (
                <ReactTable_CS
                  className="mt-4 w-full"
                  columns={columns}
                  data={userStopWordList}
                  setData={setUserStopWordList}
                  totalCount={totalCount}
                  onSortingChange={onSortingChange}
                  pageInfo={pageInfo}
                />
              )}
            </dd>
          </dl>
        </div>
      </>
    );
  }
}
