import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import qs from 'qs';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import AddAnalysisPop from './AddAnalysisPop';
import io from 'socket.io-client';
import useLoading from '../../hooks/useLoading';
import axiosClient from '../../lib/axiosClient';
import { setSelectedFileName } from '../../store/modules/Main';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const [isOpen, setIsOpen] = useState();
  const [analysisHistoryList, setAnalysisHistoryList] = useState([]);
  const loginUserData = useSelector((state) => state.Main.user);
  const currentRole = useSelector((state) => state.Main.currentProject.role);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const [analysisStatus, setAnalysisStatus] = useState([]);
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getAiAnalysisHistorys();
  }, [pageInfo, sortOption]);

  //컬럼명 설정
  useEffect(() => {
    setColumns([
      {
        header: '버전',
        id: 'version',
        size: '5%',
        cellRender: (row) => {
          return <span class="ml-2">{row.version}</span>;
        },
      },
      {
        header: '파일명',
        id: 'fileOriginName',
        size: '40%',
        cellRender: (row) => {
          return (
            <>
              <label class="check_box1 flex items-center">
                <span class="ml-2">{row.fileOriginName}</span>
              </label>
            </>
          );
        },
      },
      {
        header: '상태',
        id: 'status',
        size: '15%',
        cellRender: statusCell,
      },
      {
        header: '등록자',
        id: 'email',
        textAlign: 'text-center',
        size: '15%',
      },
      {
        header: '등록일',
        id: 'createdAt',
        textAlign: 'text-center',
        size: '15%',
      },
      {
        header: '',
        id: 'button',
        size: '10%',
        cellRender: (row) => {
          return currentRole == 1 ||
            row.createdUserNo == loginUserData.userNo ? (
            <a
              href="javascript:void(0)"
              className="btn_delimg"
              onClick={(e) => {
                e.stopPropagation();
                if (projectStatus == 10) {
                  toast.error('분석 완료된 프로젝트입니다.');
                  return;
                }
                if (confirm('삭제하시겠습니까?'))
                  deleteAiAnalysedHisotry(row.historyId);
              }}
            >
              <img
                src="/images/icon_del2.png"
                alt=""
                width="18"
                class="inline-block opacity-55"
              />
            </a>
          ) : null;
        },
      },
    ]);
  }, []);

  useEffect(() => {
    if (analysisHistoryList.length > 0) {
      // 웹소켓 연결 생성  167.179.75.7
      //http://http://149.28.30.105
      const socket = io('http://149.28.30.105:3030/checkAnalysisStatus');

      socket.on('connect', () => {
        console.log('Connected to server');

        // 연결 후 배열 또는 객체를 서버로 전송
        const data = [];
        _.forEach(analysisHistoryList, (history) => {
          data.push(history.historyId);
        });
        console.log('🚀 ~ socket.on ~ data:', data);
        socket.emit('AnalysisIdList', data);
      });

      socket.on('analysisStatus', (data) => {
        console.log('analysisStatus data:', data);
        const statusList = [];
        data.forEach((status) => {
          let temp = _.cloneDeep(status);
          delete temp.ans_id;
          delete temp.history_id
          delete temp.status
          let resultStatus = 1;
          const isFailed = _.filter(temp, (t) => t == -1).length;
          const isInprocess = _.filter(temp, (t) => t == 1).length;
          const isComplete = _.filter(temp, (t) => t == 2).length;
          if (isFailed > 0) {
            resultStatus = -1;
          } else if (isInprocess > 0) {
            resultStatus = 1;
          } else if (isComplete == 6) {
            resultStatus = 2;
          }
          statusList.push({ ansId: status.ans_id, status: resultStatus });
        });
        setAnalysisStatus(statusList);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [analysisHistoryList]);

  // AI분석 이력 삭제
  const deleteAiAnalysedHisotry = (historyId) => {
    axiosClient({
      method: 'post',
      url: '/api/project/deleteAiAnalysedHisotry',
      data: {
        historyId: historyId,
      },
    })
      .then(async (result) => {
        console.log(result.data);
        // 데이터 없을 때
        if (result.data.success == false) {
          toast.error('팀원이 없습니다.');
        } else {
          getAiAnalysisHistorys();
        }
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  // AI 분석 이력 조회
  const getAiAnalysisHistorys = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiAnalysisHistorys',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: parseInt(projectId),
        },
      },
    })
      .then(async (result) => {
        setAnalysisHistoryList(result.data.data.rows);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  const statusCell = (row) => {
    const status = getAnalysisStatus(row);
    const className =
      status == '분석중'
        ? 'statebox statebox1 px-3 rounded-full'
        : status == '분석완료'
          ? 'statebox  px-3 rounded-full bg-slate-500'
          : 'statebox  px-3 rounded-full bg_orange3';
    return (
      <>
        <div style={{ position: 'relative' }}>
          <span className={className}>{status}</span>
        </div>
      </>
    );
  };

  const getAnalysisStatus = (row) => {
    const map = {
      '-1': '분석실패',
      0: '분석대기중',
      1: '분석중',
      2: '분석완료',
    };
    const status = _.find(analysisStatus, { ansId: row.ansId })?.status;
    return status != undefined ? map[status] : row.status;
  };
  useEffect(() => {
    if (analysisStatus != {}) {
      setColumns((prev) => {
        return prev.map((old) => {
          if (old.id == 'status') {
            return {
              ...old,
              cellRender: statusCell,
            };
          } else {
            return old;
          }
        });
      });
    }
  }, [analysisStatus]);
  return (
    <>
      <div className="mt-8  flex items-center">
        <h3 className="flex items-center grow">
          <img src="/images/tit_icon4.png" alt="icon" width="33" />
          <span className="ml-2.5 text-2xl font-bold">AI분석 이력</span>
        </h3>
        <a
          href="javascript:void(0)"
          className="btn_h45 bg_orange3 text-white r5 px-5 hover:bg-slate-900"
          onClick={() => {
            if (projectStatus == 10) {
              toast.error('분석 완료된 프로젝트입니다.');
              return;
            }
            setIsOpen(true);
          }}
        >
          + 신규분석
        </a>
      </div>
      <article className="mt-10 contentsbox px-5">
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              placeholder="파일명을 입력해주세요."
              style={{ width: '350px' }}
              value={getSearchOptionValue('fileOriginName')}
              onChange={(e) => {
                setSearchOptionValue('fileOriginName', e.target.value);
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={getAiAnalysisHistorys}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        {analysisHistoryList && (
          <ReactTable
            className="mt-4 w-full"
            columns={columns}
            data={analysisHistoryList}
            setData={setAnalysisHistoryList}
            totalCount={totalCount}
            onSortingChange={onSortingChange}
            onRowClick={(row) => {
              dispatch(setSelectedFileName({ fileName: row.fileOriginName }));
              navigate(
                `/projects/aiAnalysis/detail?id=${projectId}&history=${row.historyId}`,
              );
            }}
          />
        )}
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />
      </article>

      {isOpen && (
        <AddAnalysisPop
          projectId={projectId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          getAiAnalysisHistorys={getAiAnalysisHistorys}
        />
      )}
    </>
  );
}
