import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
export default function GuideLineOptionPop({ setIsOpen }) {
  const [tab, setTab] = useState(0);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '650px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">온톨로지 시행지침검색 세부옵션 설정 </span>
            </p>
            <p className="mt-1 text-black/60">
              온톨로지 분석 시 사용할 세부 옵션을 설정하세요.
            </p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <ul id="tabs" className="gap-1">
              <li
                id={`${tab == 0 ? 'current' : ''}`}
                onClick={() => {
                  setTab(0);
                }}
              >
                <a href="javascript:void(0)" title="tab1" className="px-4">
                  유사도
                </a>
              </li>
              <li
                id={`${tab == 1 ? 'current' : ''}`}
                onClick={() => {
                  setTab(1);
                }}
              >
                <a href="javascript:void(0)" title="tab2" className="px-4">
                  SPARQL 쿼리
                </a>
              </li>
            </ul>
            <div id="addlist" className="mt-5">
              {tab == 0 && (
                <div id="tab1">
                  <p className="text-black/70">
                    연관된 노드 수 제한값을 설정합니다.
                  </p>
                  <ul className="mt-2 bg-stone-100/80 p-5 r5">
                    <li className="border-b pb-3.5">
                      <label className="flex items-center check_box2">
                        <input type="radio" name="1" className="rounded-full" />
                        <span className="ml-2 text-sm">매우 다름</span>
                      </label>
                    </li>
                    <li className="mt-3.5 flex items-center border-b pb-3.5">
                      <label className="flex items-center check_box2">
                        <input type="radio" name="1" className="rounded-full" />
                        <span className="ml-2 text-sm">다름</span>
                      </label>
                    </li>
                    <li className="mt-3.5 flex items-center  border-b pb-3.5">
                      <label className="flex items-center check_box2">
                        <input
                          type="radio"
                          name="1"
                          className="rounded-full"
                          checked
                        />
                        <span className="ml-2 text-sm">보통 (기본값)</span>
                      </label>
                    </li>
                    <li className="mt-3.5 flex items-center border-b pb-3.5">
                      <label className="flex items-center check_box2">
                        <input type="radio" name="1" className="rounded-full" />
                        <span className="ml-2 text-sm">유사</span>
                      </label>
                    </li>
                    <li className="mt-3.5 flex items-center">
                      <label className="flex items-center check_box2">
                        <input type="radio" name="1" className="rounded-full" />
                        <span className="ml-2 text-sm">매우 유사</span>
                      </label>
                    </li>
                  </ul>
                </div>
              )}
              {tab == 1 && (
                <div id="tab2">
                  <p className="text-black/70">
                    RDF데이터베이스 조회 쿼리를 작성합니다.
                  </p>
                  <p className="mt-2">
                    <textarea
                      className="textarea1 w-full p-3 r5 bg-slate-50"
                      rows="14"
                    >
                      {`SELECT ?uri ?제목 WHERE { 
          ?s a <http://xmlns.com/foaf/0.1/Agent>.
          ?s <http://purl.org/dc/elements/1.1/title> "ㅇㅇㅇ"^^xsd:string.
          ?s <http://data.riss.kr/ontology/createBook> ?uri.
          ?uri <http://data.riss.kr/ontology/heldByUniv> ?k.
          ?k <http://www.w3.org/2004/02/skos/corejavascript:void(0)prefLabel> "ㅇㅇㅇㅇ문서"^^xsd:string.
          ?uri <http://purl.org/dc/elements/1.1/title> ?제목.
    }`}
                    </textarea>
                  </p>
                </div>
              )}
            </div>
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={() => {
                if (projectStatus == 10) {
                  toast.error('분석 완료된 프로젝트입니다.');
                  return;
                }
                setIsOpen(false);
              }}
            >
              저장
            </a>
          </li>
        </ul>
        <div className="popbg btn_pop1"></div>
      </article>
    </>
  );
}
