import { React, useEffect, useState } from 'react';
import AiDifficultyAnalysisOptionPop from './AiDifficultyAnalysisOptionPop';
import useLoading from '../../../../hooks/useLoading';
import useTableControl from '../../../../hooks/useTableControl';
import LLMAnalysisPop from './LLMAnalysisPop';
import ReactTable from '../../../Components/ReactTable';
import Pagination from '../../../Components/Pagination';
import { useSearchParams } from 'react-router-dom';
import axiosClient from '../../../../lib/axiosClient';

export default function AiDifficultyAnalysis({
  analysisResult,
  analysisInfo,
  reAnalysis,
  analysisOption,
  setAnalysisOption
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [LLMOpen, setLLMOpen] = useState(false);
  const status = analysisResult?.['daStep'] || analysisInfo?.['daStep'];
  const [APIWritingComplete, setAPIWritingComplete] = useState(false); // LLM 난이도 분석 API 인증
  const { LoadingElement, loading, setLoading } = useLoading({
    init: status == 1 ? true : false,
    size: 14,
  });
  const [aiDifficultyList, setAiDifficultyList] = useState([]);
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = searchParams.get('history');

  useEffect(() => {
    setLoading(status == 1 ? true : false);
  }, [analysisResult]);

  useEffect(() => {
    getAiDifficultyList();
  }, [pageInfo, sortOption, analysisResult]);

  // 컬럼명 설정
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '13%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
      },
      {
        header: '난이도',
        id: 'difficulty_order',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty_order' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <div className="text-red-600">상</div>;
          } else if (row.difficulty == 'MID') {
            return <div className="text-blue-600">중</div>;
          } else {
            return <div>하</div>;
          }
        },
        size: '18%',
      },
    ]);
  }, []);

  // AI난이도 분석 결과 조회
  const getAiDifficultyList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiDifficultyList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          historyId,
          projectId,
        },
      },
    })
      .then(async (result) => {
        setAiDifficultyList(result.data.data.rows);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  return (
    <>
      <div className="ml-12 conbox relative" style={{ minHeight: '450px' }}>
        <div className="flex items-center justify-between">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">
              AI기반 난이도 분석 결과
            </span>
          </h4>
          <p className="flex gap-1">
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-600 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setLLMOpen(true);
              }}
            >
              LLM 기반 분석
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
              onClick={reAnalysis}
            >
              재분석
            </a>
            <a
              href="http://149.28.30.105/aiAnalysis.pdf"
              target='blank'
              download="aiAnalysis.pdf"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
            >
              다운로드
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              전문가 모드
            </a>
          </p>
        </div>
        <LoadingElement />
        {status == -1 && <div class="loading"></div>}
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              placeholder="문장을 입력해주세요."
              style={{ width: '350px' }}
              value={getSearchOptionValue('sentence')}
              onChange={(e) => {
                setSearchOptionValue('sentence', e.target.value, 1);
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={getAiDifficultyList}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        <ReactTable
          className="mt-4 w-full"
          columns={columns}
          data={aiDifficultyList}
          setData={setAiDifficultyList}
          totalCount={totalCount}
          onSortingChange={onSortingChange}
          pageInfo={pageInfo}
        />
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />

        {isOpen && <AiDifficultyAnalysisOptionPop setIsOpen={setIsOpen} reAnalysis={reAnalysis} />}
        {LLMOpen && (
          <LLMAnalysisPop
            setIsOpen={setLLMOpen}
            APIWritingComplete={APIWritingComplete}
            setAPIWritingComplete={setAPIWritingComplete}
            analysisOption={analysisOption}
            setAnalysisOption={setAnalysisOption}
          />
        )}
      </div>
    </>
  );
}
