import { React, useEffect, useState } from 'react';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import ReactTable from '../Components/ReactTable';
import axiosClient from '../../lib/axiosClient';
import { toast } from 'react-toastify';
export default function App({
  isOpen,
  setIsOpen,
  projectId,
  getProjectMember,
  getDetails,
}) {
  const [emailList, setEmailList] = useState();
  const [selectedEmail, setSelectedEmail] = useState();
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });
  const [totalCount, setTotalCount] = useState(0);

  // 모달 닫을 때 이메일 리스트 초기화
  useEffect(() => {
    if (!isOpen) {
      setEmailList([]);
      setSearchOptionValue('email', null);
    }
  }, [isOpen]);
  useEffect(() => {
    setColumns([
      {
        header: '이메일',
        id: 'email',
        textAlign: 'text-left',
      },
    ]);
  }, []);

  // 이메일 검색
  const searchUser = () => {
    axiosClient({
      method: 'POST',
      url: '/api/project/getUserListByEmail',
      data: {
        searchOptions: {
          email: getSearchOptionValue('email'),
          projectId: projectId,
        },
      },
    })
      .then(async (result) => {
        if (result.data.data.rows.length == 0) {
          toast.error('다시 검색해주세요');
        } else {
          setEmailList(result.data.data.rows);
        }
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  // 팀원 저장
  const insertMember = () => {
    if (!_.isEmpty(selectedEmail)) {
      axiosClient({
        method: 'post',
        url: '/api/project/insertProjectMember',
        data: selectedEmail,
      })
        .then(async (result) => {
          console.log(result.data);
          // 저장실패
          if (result.data.success == false) {
            toast.error('다시 시도해주세요');
          } else {
            getProjectMember();
            setSelectedEmail();
            getDetails();
            setSearchOptionValue('email', null);
            setIsOpen(false);
          }
        })
        .catch((error) => {
          console.log('🚀 ~ useEffect ~ error:', error);
          window.catchHandler(error);
        });
    } else {
      toast.error('이메일을 선택해주세요.');
    }
  };

  return (
    <>
      <article class="popbox popbox1" style={{ display: 'flex' }}>
        <ul class="pop_container pt-8" style={{ width: '500px' }}>
          <li class="shrink-0 px-8">
            <p class="poptit flex items-center">
              <span class="img mr-2 mt-0.5">
                <img src="/images/icon_invite1.png" width="20" />
              </span>
              <span class="tit">팀원초대 </span>
            </p>
            <p class="mt-1 text-black/60">프로젝트 구성원을 추가합니다.</p>
            <a
              href="javascript:void(0)"
              class="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li class="p-8 grow pop_contents">
            <div class="flex items-center gap-1">
              <label>
                <input
                  type="text"
                  class="input_h45 f_15 px-3 r5 bg-white"
                  placeholder="이메일을 입력해주세요"
                  style={{ width: '350px' }}
                  value={getSearchOptionValue('email')}
                  onChange={(e) => {
                    setSearchOptionValue('email', e.target.value);
                  }}
                />
              </label>
              <a
                href="javascript:void(0)"
                class="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-indigo-800/80 "
                onClick={searchUser}
              >
                <img
                  src="/images/icon_search1.png"
                  alt="돋보기"
                  width="13"
                  class="shrink-0 imgwhite mt-0.5"
                />
                <span class="ml-1 text-sm text-white font-bold ">검색</span>
              </a>
            </div>
          </li>
          <div class="userList">
            <ul className="px-8">
              {emailList && (
                <>
                  <ReactTable
                    className="mt-4 w-full"
                    columns={columns}
                    data={emailList}
                    setData={setEmailList}
                    totalCount={totalCount}
                    onSortingChange={onSortingChange}
                    onRowClick={(row) => {
                      setSearchOptionValue('email', row.email);
                      setSelectedEmail({
                        projectId: projectId,
                        userNo: row.userNo,
                        isManager: false,
                      });
                      setEmailList();
                    }}
                  />
                  <Pagination
                    currentPage={pageInfo.currentPage}
                    totalCount={totalCount}
                    pageSize={pageInfo.pageSize}
                    onPageChange={onPageChange}
                  />
                </>
              )}
            </ul>
          </div>
          <li class="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              class="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              class="btn_pop bg_orange3 text-white"
              onClick={insertMember}
            >
              추가
            </a>
          </li>
        </ul>
        <div class="popbg btn_pop1"></div>
      </article>
    </>
  );
}
