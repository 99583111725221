import { React, useEffect, useRef, useState } from 'react';
import EChartsReact from 'echarts-for-react';

export default function NetworkChartEnlarge({
  setIsActive,
  setKeyWord,
  setViewCountSort,
  keyWord,
  viewCountSort,
  data,
  crowd,
}) {
  const inputRef = useRef(null);
  const [networkChartOptions, setNetworkChartOptions] = useState({});
  const [key, setKey] = useState(0);

  const handleZoomSize = (viewCountSort) => {
    if (viewCountSort == -1) {
      return 1.3;
    } else if (viewCountSort == 30) {
      return 2.5;
    } else if (viewCountSort == 20) {
      return 3;
    } else if (viewCountSort == 10) {
      return 4;
    }
  };

  useEffect(() => {
    if (data) {
      const nodes = [];
      const links = [];
      const nodeMap = new Map(); // 노드 중복 확인용
      setKey(key + 1);
      // 노드와 링크 생성

      const slicedData =
        viewCountSort == -1 ? data : data.slice(0, viewCountSort);

      slicedData.forEach((item) => {
        if (!nodeMap.has(item.term1)) {
          nodes.push({
            name: item.term1,
            value: item.freq,
            symbolSize: Math.sqrt(item.freq),
          });
          nodeMap.set(item.term1, true);
        }
        if (!nodeMap.has(item.term2)) {
          nodes.push({
            name: item.term2,
            value: item.freq,
            symbolSize: Math.sqrt(item.freq),
          });
          nodeMap.set(item.term2, true);
        }

        // opacity 업데이트
        nodes.forEach((node) => {
          node.itemStyle = {
            opacity: keyWord
              ? node.name == keyWord ||
                slicedData.some(
                  (item) =>
                    (item.term1 == node.name && item.term2 == keyWord) ||
                    (item.term2 == node.name && item.term1 == keyWord),
                )
                ? 1
                : 0.2
              : 1,
          };
        });

        links.push({
          source: item.term1,
          target: item.term2,
          value: item.freq,
          // 링크의 스타일을 기본으로 설정
          lineStyle: {
            color: '#000',
            width: 1,
            opacity: 0.3,
          },
        });
      });

      // 강조된 노드와 연결된 링크 스타일 설정
      if (keyWord) {
        links.forEach((link) => {
          if (link.source === keyWord || link.target === keyWord) {
            link.lineStyle = {
              color: 'orange',
              width: 5,
              opacity: 1,
            };
          }
        });
      }

      setNetworkChartOptions({
        title: {},
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'node') {
              const nodeName = params.data.name;
              const connectedNodes = links.filter(
                (link) => link.source === nodeName || link.target === nodeName,
              );
              const hoverView = connectedNodes
                .map((connectedNode) => {
                  const targetNode =
                    params.name === connectedNode.target
                      ? connectedNode.source
                      : connectedNode.target;

                  return `<span style="font-weight: bold; color: red;">${params.name}</span> >
                          <span style="font-weight: bold; color: blue;">${targetNode}</span> ${connectedNode.value}`;
                })
                .join('<br>');
              return hoverView;
            } else if (params.dataType === 'edge') {
              return `<span style="font-weight: bold; color: red;">${params.data.source}</span> > <span style="font-weight: bold; color: blue;">${params.data.target}</span> ${params.data.value}`;
            }
          },
        },
        legend: {},
        series: [
          {
            zoom: handleZoomSize(viewCountSort),
            layout: 'force',
            type: 'graph',
            legendHoverLink: false,
            data: nodes,
            links: links,
            roam: true,
            force: {
              layoutAnimation: false,
              repulsion: 100,
              gravity: crowd,
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}',
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                color: '#000',
                width: 5,
              },
            },
          },
        ],
      });
    }
  }, [data, keyWord, viewCountSort]);

  return (
    <>
      <article className="fixed inset-0 z-50 flex justify-center items-center ">
        <ul className="pop_container pt-8 w-[700px] ">
          <li className="shrink-0 px-8 ">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-1">
                <img src="/images/icon_select1.png" width="25" />
              </span>
              <span className="tit">네트워크 분석 결과 </span>
            </p>

            <div className="px-3 mt-8 flex items-center gap-1">
              <label>
                <input
                  type="text"
                  ref={inputRef}
                  defaultValue={keyWord}
                  className="input_h45 f_15 px-3 r5 bg-slate-50"
                  placeholder="문장을 입력해주세요."
                  style={{ width: '450px' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setKeyWord(inputRef.current.value); // Enter 키를 눌렀을 때 입력값으로 상태 업데이트
                    }
                  }}
                />
              </label>
              <a
                href="javascript:void(0)"
                className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-indigo-800/80"
                onClick={() => {
                  setKeyWord(inputRef.current.value);
                }}
              >
                <img
                  src="/images/icon_search1.png"
                  alt="돋보기"
                  width="13"
                  className="shrink-0 imgwhite mt-0.5"
                />
                <span className="ml-1 text-sm text-white font-bold">검색</span>
              </a>
              <select
                className="f_15 px-3 r5 bg-slate-50 input_h45"
                onChange={(e) => {
                  setViewCountSort(e.target.value);
                }}
              >
                <option value="-1" selected={viewCountSort == 1 ? true : false}>
                  전체
                </option>
                <option
                  value="30"
                  selected={viewCountSort == 30 ? true : false}
                >
                  30개
                </option>
                <option
                  value="20"
                  selected={viewCountSort == 20 ? true : false}
                >
                  20개
                </option>
                <option
                  value="10"
                  selected={viewCountSort == 10 ? true : false}
                >
                  10개
                </option>
              </select>
            </div>

            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsActive(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />
            </a>
          </li>
          <li className="p-8 grow pop_contents flex justify-center items-center ">
            <div className="relative w-[500px] h-[500px] ">
              <EChartsReact
                key={`EnLargeNetwork_${key}`}
                option={networkChartOptions}
                style={{ width: '100%', height: '100%' }} // 차트 크기
              />
            </div>
          </li>
          <li className="mt-2 pb-8 shrink-0 flex_center gap-1 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300 px-16 r5"
              onClick={() => {
                setIsActive(false);
              }}
            >
              닫기
            </a>
          </li>
        </ul>
      </article>
    </>
  );
}
