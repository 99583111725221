import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { values } from 'lodash';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import OptionPop from './OptionPop';
import { useSelector } from 'react-redux';
import useLoading from '../../hooks/useLoading';
import axiosClient from '../../lib/axiosClient';
import { toast } from 'react-toastify';

export default function AiRegist() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const [document, setDocument] = useState({
    projectId,
    whitespaceRemoval: 1,
    specialRemoval: 1,
    chinesecharRemoval: 1,
  });
  const [sentenceList, setSentenceList] = useState([]);
  const [fileName, setFileName] = useState('선택된 파일 없음');
  const [file, setFile] = useState(null); // 파일 상태
  const [processedList, setProcessedList] = useState([]);
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 10 });
  const [totalCount, setTotalCount] = useState(0);

  const { LoadingElement, loading, setLoading } = useLoading({
    init: false,
    size: 13,
  });

  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '7%',
      },
      {
        header: '원본문장',
        id: 'originSentence',
        textAlign: 'text-left',
      },
      {
        header: '주요단어',
        id: 'word',
        size: '40%',
        textAlign: 'text-left',
        cellRender: (row) => {
          return <>{row.word.replaceAll(' ', ', ')}</>;
        },
      },
    ]);
  }, []);

  useEffect(() => {
    getSentenceList();
  }, [pageInfo, sortOption]);

  // 분석대상 문장 목록 조회
  const getSentenceList = async () => {
    if (processedList.length > 0) {
      const startIndex = (pageInfo.currentPage - 1) * pageInfo.pageSize;
      const endIndex = startIndex + pageInfo.pageSize;
      const responseData = processedList.slice(startIndex, endIndex);
      setSentenceList(responseData);
    }
  };

  const preprocessDocument = async (file) => {
    setLoading(true);
    setSentenceList([]);
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'preprocessData',
      JSON.stringify({
        whitespaceRemoval: document?.whitespaceRemoval || 1,
        specialRemoval: document?.specialRemoval || 1,
        chinesecharRemoval: document?.chinesecharRemoval || 1,
        projectId: parseInt(projectId),
        action: 'preprocess',
      }),
    );
    try {
      const response = await axiosClient.post(
        '/api/analysis/docPreprocess',
        formData,
      );
      if (response.status == 200) {
        const result = response.data;
        if (!_.isArray(result)) {
          toast.error('hwp 파일만 사용가능합니다.');
        } else {
          const startIndex = (pageInfo.currentPage - 1) * pageInfo.pageSize;
          const endIndex = startIndex + pageInfo.pageSize;
          const responseData = result.slice(startIndex, endIndex);
          setSentenceList(responseData);
          setProcessedList(result);
          setTotalCount(result.length);
          setFile(file);
          setFileName(file.name);
        }
      } else {
        throw new Error('서버 문제로 업로드 실패.');
      }
    } catch (error) {
      console.error('업로드 중 에러 발생:', error);
      toast.error('업로드 중 오류가 발생했습니다.');
    }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error('파일을 선택해 주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('projectId', projectId);
    try {
      axiosClient
        .post('/api/analysis/docUpload', formData)
        .then(async (res) => {
          if (res.data.status == 'OK') {
            const data = res.data.data;
            const response = await axiosClient
              .post('/api/analysis/insertPeprocessData', {
                ...data,
                sentences: processedList,
              })
              .catch((e) => {
                console.error('업로드 중 에러 발생:', error);
              });
            if (response.status == 200) {
              navigate(`/projects/analysisTarget?id=${projectId}`);
            }
          }
        })
        .catch((e) => {
          console.error('업로드 중 에러 발생:', error);
        });
    } catch (error) {
      console.error('업로드 중 에러 발생:', error);
      toast.error('업로드 중 오류가 발생했습니다.');
    }
  };

  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon3.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">신규 분석대상 등록</span>
      </h3>
      <article className="mt-10 contentsbox px-5">
        <div className="relative">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">분석대상</span>
          </h4>
          <div className="mt-4 filebox  flex items-center w-3/12">
            <input
              className="upload-name mr-1 w-full"
              value={fileName}
              placeholder="문서를 선택해 주세요."
            />
            <input
              type="file"
              id="file"
              className="w-full "
              onChange={async (e) => {
                setFile(null);
                setFileName('');
                const file = e.target.files[0];
                if (file) {
                  await preprocessDocument(file);
                } else {
                  setFileName('선택된 파일 없음');
                }
                e.target.value = '';
              }}
            />
            <label for="file" className="px-5 r5 shrink-0 ">
              <span className="text-white">파일 첨부 선택</span>
            </label>
          </div>
        </div>
        <div className="mt-10 relative" style={{ minHeight: '300px' }}>
          <LoadingElement />
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">전처리 결과</span>
          </h4>
          {sentenceList && (
            <>
              <ReactTable
                className="mt-4 w-full"
                columns={columns}
                data={sentenceList}
                setData={setSentenceList}
                totalCount={totalCount}
                onSortingChange={onSortingChange}
                pageInfo={pageInfo}
              ></ReactTable>
              <Pagination
                currentPage={pageInfo.currentPage}
                totalCount={totalCount}
                pageSize={pageInfo.pageSize}
                onPageChange={onPageChange}
              />
            </>
          )}
        </div>
      </article>

      {/* <!--비활성화 버튼class deactivation--> */}
      <article className="mt-8 pt-8 shrink-0 border-t-2 flex justify-between">
        <a
          href="javascript:void(0)"
          className="btn_h45 btn_line r5 font-bold w-52"
          onClick={() => {
            navigate(`/projects/analysisTarget?id=${projectId}`);
          }}
        >
          취소
        </a>
        <p className="flex gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_blue r5 font-bold w-52"
            onClick={handleUpload}
          >
            저장
          </a>
        </p>
      </article>
    </>
  );
}
