import { useState, useEffect } from 'react';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import axios from 'axios';
import useExcel from '../../hooks/useExcelProcessor';
import ReactTable_CS from '../Components/ReactTable_CS';
import axiosClient from '../../lib/axiosClient';
export default function UserWord({
  document,
  setUserWordData,
  setDeletedUserWordList,
  setUserWordImported,
  tab,
}) {
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);

  const [userWordList, setUserWordList] = useState(undefined);
  const { excelDownload, excelUpload, fileName } = useExcel({
    dataList: userWordList,
    setDataList: setUserWordList,
    setTotalCount: setTotalCount,
    columnIdList: columns,
  });

  //컬럼 설정
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
      },
      {
        header: '단어',
        id: 'word',
        editable: true,
      },
      {
        header: '삭제',
        id: 'delete',
        cellRender: (row, meta) => {
          return (
            <>
              <button
                onClick={() => {
                  meta.deleteData(row);
                  setDeletedUserWordList((old) => [...old, row.wordId]);
                  setTotalCount((prev) => prev - 1);
                }}
              >
                삭제
              </button>
            </>
          );
        },
      },
    ]);
  }, []);

  const getUserWordList = async () => {
    const response = await axiosClient.post('/api/analysis/getUserWordList', {
      ...pageInfo,
      sortOptions: sortOption,
      searchOptions: {
        ...searchOptions,
        projectId: parseInt(document.projectId),
      },
    });
    if (response.data.status == 'OK') {
      setUserWordList(response.data.data.rows);
      setTotalCount(response.data.data.totalCount);
    }
  };

  useEffect(() => {
    getUserWordList();
  }, [sortOption]);

  useEffect(() => {
    setUserWordData(userWordList);
  }, [userWordList]);

  const addRow = () => {
    const targetId = columns.find((column) => column.header == '번호').id;
    if (targetId == 'reverse') {
      setUserWordList([{ wordId: 0, word: '' }, ...userWordList]);
    } else {
      setUserWordList([...userWordList, { wordId: 0, word: '' }]);
    }
    setTotalCount((prev) => prev + 1);
  };

  if (tab == 0) {
    return (
      <>
        <div id="tab2" style={{ minHeight: '430px' }}>
          <dl>
            <dt class="relative">
              <div class="flex items-center justify-between">
                <p className="leading-6 grow">
                  <span className="font-bold"> 사용자 정의 용어사전</span>
                  <br />
                  <span className="text-sm text-black/60">
                    추가할 용어를 입력하세요.
                  </span>
                </p>
                <div class="flex gap-1">
                  <label
                    htmlFor="file-upload"
                    className="btn_h35 r5 px-4 bg-slate-500 text-white cursor-pointer"
                    style={{
                      background: '#0f8107',
                    }}
                  >
                    엑셀 업로드
                  </label>
                  <input
                    id="file-upload"
                    className="hidden"
                    type="file"
                    accept=".xlsx"
                    onChange={(e) => {
                      setUserWordImported(1);
                      excelUpload(e);
                      e.target.value = '';
                    }}
                  />
                  <a
                    href="javascript:void(0)"
                    class="btn_h35 r5 px-4"
                    style={{
                      background: '#0f8107',
                      color: '#fff',
                    }}
                    onClick={() => { excelDownload() }}
                  >
                    엑셀 추출
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn_h35 r5 px-4 "
                    style={{
                      background: '#0f8107',
                      color: '#fff',
                    }}
                    onClick={addRow}
                  >
                    단어추가
                  </a>
                </div>
              </div>
            </dt>
            <dd>
              {userWordList && (
                <ReactTable_CS
                  className="mt-4 w-full"
                  columns={columns}
                  data={userWordList}
                  setData={setUserWordList}
                  totalCount={totalCount}
                  onSortingChange={onSortingChange}
                  pageInfo={pageInfo}
                />
              )}
            </dd>
          </dl>
        </div>
      </>
    );
  }
}
