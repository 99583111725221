import { React, useEffect, useState } from 'react';
import OntologyDocPop from './OntologyDocPop';
import OntologyOptionPop from './OntologyOptionPop';
import { useSelector } from 'react-redux';
import ReactTable from '../../../Components/ReactTable';
import Pagination from '../../../Components/Pagination';
import { useSearchParams } from 'react-router-dom';
import axiosClient from '../../../../lib/axiosClient';
import useLoading from '../../../../hooks/useLoading';
import useTableControl from '../../../../hooks/useTableControl';

export default function OntologyAnalysis({
  reAnalysis,
  analysisResult,
  analysisInfo,
  analysisOption
}) {
  const status = analysisResult?.['daStep'] || analysisInfo?.['daStep'];
  const [docPop, setDocPop] = useState(false);
  const [optionPop, setOptionPop] = useState(false);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const { LoadingElement, loading, setLoading } = useLoading({
    init: status == 1 ? true : false,
    size: 14,
  });
  const [aiDifficultyList, setAiDifficultyList] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = searchParams.get('history');

  // 클릭된 로우 하이라이트 처리
  const clickedRowHighlight = (data) => {
    if (selectedRow) {
      setAiDifficultyList(
        data?.map((item) =>
          selectedRow?.difficultyId == item.difficultyId
            ? {
              ...item,
              style: {
                background: '#bbbb',
              },
            }
            : {
              ...item,
              style: null,
            },
        ),
      );
    } else {
      setAiDifficultyList(data);
    }
  };

  // AI난이도 분석 결과 조회
  const getAiDifficultyList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiDifficultyList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          historyId,
          projectId,
          llmData: analysisOption.useLlm
        },
      },
    })
      .then(async (result) => {
        clickedRowHighlight(result.data.data.rows)
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  useEffect(() => {
    setLoading(status == 1 ? true : false);
  }, [analysisResult]);

  useEffect(() => {
    getAiDifficultyList();
  }, [pageInfo, sortOption, analysisResult]);

  // 컬럼명 설정
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '13%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
      },
      {
        header: '난이도 문장',
        id: 'difficulty_order',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty_order' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <div className="text-red-600">상</div>;
          } else if (row.difficulty == 'MID') {
            return <div className="text-blue-600">중</div>;
          } else {
            return <div>하</div>;
          }
        },
        size: '18%',
      },
    ]);
  }, []);

  useEffect(() => {
    clickedRowHighlight(aiDifficultyList);
  }, [selectedRow]);

  return (
    <>
      <div className="ml-12 conbox relative">
        <div className="flex items-center justify-between">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">
              AI기반 난이도 분석 결과
            </span>
          </h4>
          <p className="flex gap-1">
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
              onClick={reAnalysis}
            >
              재분석
            </a>
            <a
              href="http://149.28.30.105/aiAnalysis.pdf"
              target='blank'
              download="aiAnalysis.pdf"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
            >
              다운로드
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setOptionPop(true);
              }}
            >
              전문가 모드
            </a>
          </p>
        </div>

        <LoadingElement />
        {status == -1 && <div class="loading"></div>}
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              placeholder="문장을 입력해주세요."
              style={{ width: '350px' }}
              value={getSearchOptionValue('sentence')}
              onChange={(e) => {
                setSearchOptionValue('sentence', e.target.value, 1);
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={getAiDifficultyList}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        <ReactTable
          className="mt-4 w-full"
          columns={columns}
          data={aiDifficultyList}
          setData={setAiDifficultyList}
          totalCount={totalCount}
          onSortingChange={onSortingChange}
          pageInfo={pageInfo}
          onRowClick={(row) => {
            setSelectedRow(row);
          }}
        />
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />

        <dl className="mt-8">
          <dt className=" text-lg font-bold">대상문장</dt>
          <dd className="mt-2 flex items-center gap-1">
            <label className="grow">
              <input
                type="text"
                className="input_h45 f_15 px-3 r5 bg-slate-50 w-full"
                value={`문장 : ${selectedRow
                  ? selectedRow.sentence
                  : '위의 테이블에서 문장을 선택해주세요'
                  }`}
              />
            </label>
            <a
              href="javascript:void(0)"
              className="btn_h45 r5 px-7 bg-indigo-950/40 hover:bg-indigo-900 "
              onClick={() => {
                setDocPop(true);
              }}
            >
              <span className="text-sm text-white font-bold ">변경</span>
            </a>
          </dd>
        </dl>
        <dl className="mt-8">
          <dt>
            <p className="text-lg font-bold">
              온톨로지 기반 문장 난이도 분석결과
            </p>
            <p className="mt-2 text-black/80">
              - BIM 기술 온톨로지 + AI 학습무장 BIM 기술 난이도 키워드를
              조합하여 온톨로지의 시각적 표출 및 관련 내용 해석(문장 제공)
            </p>
          </dt>

          <dd className="mt-2 grid grid-cols-2 gap-4 bg-slate-100/80 p-5 r5">
            <div className="col-span-2 font-bold">
              3. 현장의 좌표계와 도로의평면, 종횡단 등 선형정보가 정확히
              일치하도록 하여야 하고, 공용중인 도로의 정보가 정확히 반영되어
              접속부 등에서 오류가 발생하지 않도록 하여야 한다.
            </div>
            <div className="bg-white p-5 r5"></div>
            <div className="bg-white/80 p-5 r5">
              <p className="mt-1.5  f_15 leading-6 text-black/70">
                해당 BIM기술은 (적용단계)에 (적용대상)을/를 대상으로 활용되며,
                (입력정보)을/를 필요로 하며 해당 기술의 적용을 통해 (출력정보)를
                될 수 있습니다.
              </p>
              <p className="mt-2 f_15 leading-6 text-black/70">
                해당 기술의 난이도는 (난이도 점수/5) 이며, 문장내 (난이도
                키워드1), (난이도 키워드2)를 통해 기술의 난이도는 (AI
                결과값)으로 도출되었습니다.
              </p>
            </div>
          </dd>
          <dd className="mt-2 flex justify-end gap-1 ">
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-950/10  px-6 r5 hover:bg-black hover:text-white"
            >
              상세 파라미터 설정(전문가 모드)
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-800 px-6 r5 hover:bg-black text-white"
            >
              재 분석
            </a>
          </dd>
        </dl>
      </div>
      {docPop && (
        <OntologyDocPop setIsOpen={setDocPop} projectStatus={projectStatus} />
      )}
      {optionPop && (
        <OntologyOptionPop
          setIsOpen={setOptionPop}
          projectStatus={projectStatus}
        />
      )}
    </>
  );
}
