import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactTable_CS from '../../../../Components/ReactTable_CS';
import useTableControl from '../../../../../hooks/useTableControl';
import axiosClient from '../../../../../lib/axiosClient'
import { toast } from 'react-toastify';
export default function AnalysisTargetList({ docId, setResultList, setLoading }) {
  const [sentenceList, setSentenceList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams()
  const projectId = searchParams.get("id")
  const historyId = searchParams.get("history")
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  // 선택된 아이템을 저장하는 상태
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [checkAll, setCheckAll] = useState(false);

  const handleSelectAll = () => {
    setCheckAll(prev => {
      const newCheckAll = !prev;
      setSelectedItems(() => {
        if (newCheckAll) {
          // 전체 선택
          return new Set(sentenceList.map(item => item.sentenceId));
        } else {
          // 전체 선택 해제
          return new Set();
        }
      });
      return newCheckAll;
    });
  };

  // 개별 체크박스 핸들러
  const handleSelectItem = (itemId) => {
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        if (checkAll) {
          setCheckAll(false)
        }
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };


  useEffect(() => {
    setColumns([
      {
        header: () => {
          return (
            <>
              <label className="check_box1 flex_center" onClick={handleSelectAll}>
                <input
                  type="checkbox"
                  className="r4"
                  name="1"
                  checked={checkAll}
                  onChange={() => { }}
                />
              </label>
            </>
          )
        },
        id: 'check',
        size: '7%',
        cellRender: (row) => {
          return (
            <>
              <label className="check_box1 flex_center" onClick={() => { handleSelectItem(row.sentenceId) }}>
                <input
                  type="checkbox"
                  className="r4"
                  name="1"
                  checked={selectedItems.has(row.sentenceId)}
                  onChange={() => { }}
                />
              </label>
            </>
          )
        }
      },
      {
        header: '번호',
        id: 'reverse',
        size: '13%',
      },
      {
        header: '문장',
        id: 'originSentence',
        textAlign: 'text-left',
      }
    ]);
  }, [selectedItems, checkAll, sentenceList]);
  useEffect(() => {
    getSentenceList();
  }, [pageInfo, sortOption]);
  // 분석대상 문장 목록 조회
  const getSentenceList = async () => {
    axios({
      method: 'post',
      url: '/api/project/getSentenceList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          docId,
        },
        usePagination: false
      },
    })
      .then(async (result) => {
        setSentenceList(result.data.data.rows);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  const startLLMAnalysis = async () => {
    const data = _.filter(sentenceList, v => selectedItems.has(v.sentenceId))
    if (data.length == 0) {
      toast.warn("분성대상을 설정해주세요.")
      return
    }
    setLoading(true)
    setResultList([])
    const response = await axiosClient.post("/api/analysis/startLLMAnalysis", { sentences: data, historyId, projectId })
    if (response.status == 200) {
      setResultList?.(response.data)
      setLoading(false)
    }
  }

  return (
    <>
      {sentenceList && (
        <>
          <ReactTable_CS
            className="mt-4 w-full"
            columns={columns}
            data={sentenceList}
            setData={setSentenceList}
            totalCount={totalCount}
            onSortingChange={onSortingChange}
            pageInfo={pageInfo}
          />

        </>
      )}
      <a
        href="javascript:void(0)"
        className="btn_pop bg_orange3 text-white  text-center mt-2 mb-5 px-5 r5 col-span-1 float-right"
        onClick={startLLMAnalysis}
      >
        분석대상 설정 완료 & 분석 시작
      </a>
    </>
  )
}